<template>
  <div class="div-form-main invoice-form" v-loading="isInvoiceFormLoading">
    <div class="div-form-main-btn"  v-if="!isInvoiceFormLoading && !isViewOnly">
      <el-button round type="primary" size="small" @click="submitInvoice(true)" v-if="propsView === INVOICE_VIEW.CREATE_INVOICE">Submit</el-button>
      <el-button round type="primary" size="small" @click="submitInvoice(false)" v-if="propsView === INVOICE_VIEW.EDIT_INVOICE">Submit</el-button>
      <el-button round type="primary" size="small" @click="handleInvoiceFormEditButtonClick"
        v-if="propsView === INVOICE_VIEW.VIEW_INVOICE && hasPermission($permissionFunctionCode.EDIT) && (isSellerView
        && ((!isCreatedWithOrder && [INVOICE_STATUS.AUTHORIZED_SIGNATORY_REJECTED, INVOICE_STATUS.CREDIT_AUTHORIZATION_REJECTED].includes(invoiceForm.sellerInvoiceStatus))
          || (isCreatedWithOrder && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && invoiceForm.sellerInvoiceStatus === INVOICE_STATUS.CREATED)
          || (isCreatedWithOrder && invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && [INVOICE_STATUS.AUTHORIZED_SIGNATORY_REJECTED, INVOICE_STATUS.PENDING_AUTHORIZED_SIGNATORY_APPROVAL].includes(invoiceForm.sellerInvoiceStatus))
        ) || (!isSellerView
        && (invoiceForm.buyerInvoiceStatus === INVOICE_STATUS.PENDING_TRADER_APPROVAL && isCreatedWithOrder))
        )"
      >
        Edit
      </el-button>
      <el-button round type="primary" size="small" v-if="isApplyCdNote" @click="applyCdNote">
        Submit
      </el-button>
      <el-button round type="primary" size="small" @click="handleApplyCdNoteClick"
        v-if="!isApplyCdNote && (propsView === INVOICE_VIEW.EDIT_INVOICE || propsView === INVOICE_VIEW.VIEW_INVOICE) && hasPermission($permissionFunctionCode.EDIT) && invoiceForm.invoiceType !== INVOICE_TYPE.PROFORMA
        && (isSellerView && [INVOICE_STATUS.SENT, INVOICE_STATUS.PAID, INVOICE_STATUS.OUTSTANDING].includes(invoiceForm.sellerInvoiceStatus)
          || (!isSellerView && invoiceForm.createdBySysOrganization === myCompanyId && [INVOICE_STATUS.AUTHORIZED_SIGNATORY_APPROVED, INVOICE_STATUS.PAID, INVOICE_STATUS.OUTSTANDING].includes(invoiceForm.sellerInvoiceStatus)))"
      >
        Apply Credit/Debit Note
      </el-button>
      <el-button v-if="!isViewOnly" round  outline size="small" @click="handleCloseInvoiceForm('CLOSE')">Close</el-button>
      <el-dropdown class="el-dropdown-form-more-btn" trigger="click" v-if="(propsView === INVOICE_VIEW.EDIT_INVOICE || propsView === INVOICE_VIEW.VIEW_INVOICE) && hasPermission($permissionFunctionCode.EDIT)
        && ((isSellerView && [INVOICE_STATUS.SENT, INVOICE_STATUS.CREATED, INVOICE_STATUS.CREDIT_AUTHORIZATION_REJECTED, INVOICE_STATUS.AUTHORIZED_SIGNATORY_REJECTED].includes(invoiceForm.sellerInvoiceStatus))
        || (!isSellerView && [INVOICE_STATUS.PENDING_TRADER_APPROVAL].includes(invoiceForm.buyerInvoiceStatus) && invoiceForm.createdBySysOrganization === myCompanyId ))"
        @command="handleInvoiceFormDropdownClick"
      >
        <el-button
          class="el-dropdown-more-btn"
          size="small"
          round
        >
          <em class="el-icon-more"></em>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-if="isSellerView && invoiceForm.sellerInvoiceStatus === INVOICE_STATUS.SENT"
            command="CANCEL"
          >
            Cancel Invoice
          </el-dropdown-item>
          <el-dropdown-item
            v-if="((isSellerView && [INVOICE_STATUS.CREATED, INVOICE_STATUS.CREDIT_AUTHORIZATION_REJECTED, INVOICE_STATUS.AUTHORIZED_SIGNATORY_REJECTED].includes(invoiceForm.sellerInvoiceStatus))
              || (!isSellerView && [INVOICE_STATUS.PENDING_TRADER_APPROVAL].includes(invoiceForm.buyerInvoiceStatus) && invoiceForm.createdBySysOrganization === myCompanyId ))"
            command="DELETE"
          >
            Delete Invoice
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="div-form-main-container" v-if="!isInvoiceFormLoading && [INVOICE_VIEW.CREATE_INVOICE, INVOICE_VIEW.VIEW_INVOICE, INVOICE_VIEW.EDIT_INVOICE].includes(propsView)">
      <el-form :model="invoiceForm" ref="invoiceForm" :validate-on-rule-change="false"  >
        <div class="basic-card-block  margin-top-0">
          <h1>Invoice Information</h1>
          <div>
            <div class="div-upload-commission-checkbox" v-if="!isSellerView && propsView === INVOICE_VIEW.CREATE_INVOICE">
              <div>
                <el-upload
                  :action="fileUploadUrl"
                  :accept="'.pdf'"
                  :limit="1"
                  :file-list="invoiceForm.fileList"
                  :on-success="handleInvoiceUploadSuccess()"
                  :before-remove="handleInvoiceBeforeRemove"
                >
                  <el-button plain round  type="primary">
                    <span class="span-red-asterik">*</span> Upload Invoice
                  </el-button>
                </el-upload>
              </div>
              <el-checkbox class="checkbox-commission" v-if="!isSellerView && invoiceForm.orderType === ORDER_TYPE.DELIVERED" v-model="isCommissionInvoice" @change="handleInvoiceTypeSelect($event, true)">
                Commission Invoice
              </el-checkbox>
            </div>
            <div class="div-frac-13">
              <el-form-item label="Invoice Type" prop="invoiceType" :rules="rules.reqRule">
                <el-select
                  :disabled="(invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && Object.keys(orderSelected).length > 0) && isSellerView || propsView === INVOICE_VIEW.VIEW_INVOICE || propsView === INVOICE_VIEW.EDIT_INVOICE || isCommissionInvoice"
                  v-model="invoiceForm.invoiceType"
                  @change="handleInvoiceTypeSelect($event, false)"
                >
                  <el-option v-for="item in INVOICE_TYPE_OPTIONS" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Invoice Date" prop="invoiceDate" :rules="rules.reqRule">
                <el-date-picker
                  type="date" format="dd-MM-yyyy"
                  v-model="invoiceForm.invoiceDate"
                  @change="handleInvoiceDateChange"
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                />
              </el-form-item>
              <el-form-item label="Invoice Due Date" prop="invoiceDueDate" :rules="invoiceForm.invoiceDate ? rules.reqRule : rules.notReqRule">
                <el-date-picker
                  :disabled="!invoiceForm.invoiceDate || propsView === INVOICE_VIEW.VIEW_INVOICE"
                  type="date" format="dd-MM-yyyy"
                  v-model="invoiceForm.invoiceDueDate"
                  :picker-options="invoiceDueDatePickerOptions()"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="basic-card-block">
          <h1>Order Information</h1>
          <div class="div-order-info-body">
            <el-button
              v-if="(invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && propsView === INVOICE_VIEW.CREATE_INVOICE)
                || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && propsView === INVOICE_VIEW.CREATE_INVOICE)"
              round outline size="mini"
              @click="dialogSelectOrder = true"
            >
              <span class="span-red-asterik">*</span> Select Order
            </el-button>
            <div class="div-frac-13">
              <el-form-item label="Order Number" prop="orderNo" :rules="rules.reqRule">
                <el-input
                  :disabled="(!(invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && Object.keys(orderSelected).length === 0)) || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL)"
                  v-model="invoiceForm.orderNo">
                </el-input>
              </el-form-item>

              <el-form-item v-if="isSellerView" label="Seller Name" prop="sellerId" :rules="rules.reqRule">
                <el-select
                  v-model="invoiceForm.sellerId"
                  filterable
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE ||(invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && Object.keys(orderSelected).length === 0) || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && !isCommissionInvoice)"
                >
                  <el-option
                    v-for="item in currentCompanyUserList"
                    :key="item.id" :label="item.name" :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item v-else label="Buyer Name" prop="buyerId" :rules="rules.reqRule">
                <el-select
                  v-model="invoiceForm.buyerId"
                  filterable
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE ||((invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA || invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL) && Object.keys(orderSelected).length === 0)"
                >
                  <el-option
                    v-for="item in currentCompanyUserList"
                    :key="item.id" :label="item.name" :value="item.id"
                  />
                </el-select>
              </el-form-item>
               <el-form-item label="Port" prop="orderPort" :rules="rules.reqRule">
                <el-input
                  v-model="invoiceForm.orderPort"
                  :disabled="(invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && Object.keys(orderSelected).length === 0) || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && Object.keys(orderSelected).length === 0)"
                >
                </el-input>
              </el-form-item>
              <el-form-item v-if="invoiceForm.orderType === ORDER_TYPE.DELIVERED || invoiceForm.orderType === ORDER_TYPE.BARGING_NOMINATION" label="Vessel" prop="orderVesselName" :rules="rules.reqRule">
                <el-select
                  v-model="invoiceForm.orderVesselName"  filterable @change="handleVesselNameSelect"
                  :disabled="(invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && Object.keys(orderSelected).length === 0) || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && Object.keys(orderSelected).length === 0)"
                >
                  <el-option
                    v-for="item in vesselList"
                    :key="item.id" :label="item.shipName" :value="item.shipName"
                  />
                </el-select>
              </el-form-item>
              <el-form-item v-if="invoiceForm.orderType === ORDER_TYPE.DELIVERED || invoiceForm.orderType === ORDER_TYPE.BARGING_NOMINATION" label="Imo No." prop="orderVesselImo" :rules="rules.reqRule">
                <el-select v-model="invoiceForm.orderVesselImo" filterable @change="handleVesselImoSelect"
                  :disabled="(invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && Object.keys(orderSelected).length === 0) || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && Object.keys(orderSelected).length === 0)"
                >
                  <el-option
                    v-for="item in vesselList"
                    :key="item.id" :label="item.imo" :value="item.imo"
                  />
                </el-select>
              </el-form-item>
              <el-form-item v-else label="Terminal" prop="orderTerminal" :rules="rules.reqRule">
                <el-select
                  v-model="invoiceForm.orderTerminal"
                  :disabled="(invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && Object.keys(orderSelected).length === 0) || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && Object.keys(orderSelected).length === 0)"
                >
                  <el-option
                    v-for="item in locationList"
                    :key="item.name" :label="item.name" :value="item.name"
                  />
                </el-select>
              </el-form-item>

              <el-form-item label="Location" prop="orderLocation">
                <el-input
                  v-model="invoiceForm.orderLocation"
                  :disabled="(invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && Object.keys(orderSelected).length === 0) || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && Object.keys(orderSelected).length === 0)"
                >
                </el-input>
              </el-form-item>
            <!-- </div>
            <div class="div-frac-13"> -->
               <el-form-item label="Currency" prop="orderCurrency" :rules="rules.reqRule">
                <el-select v-model="invoiceForm.orderCurrency" disabled @change="calculateInvoiceValues">
                  <el-option
                    v-for="item in currencyList"
                    :key="item.id" :label="item.code" :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Payment Terms" prop="orderPaymentTerm" :rules="rules.reqRule">
                <el-select
                  v-model="invoiceForm.orderPaymentTerm"
                  :disabled="(invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && Object.keys(orderSelected).length === 0) || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && Object.keys(orderSelected).length === 0)"
                  @change="handlePaymentTermChange"
                >
                  <el-option
                    v-for="item in PAYMENT_TERM_OPTIONS"
                    :key="item.value" :label="item.label" :value="item.value"
                  />
                </el-select>
              </el-form-item>

              <el-form-item v-if="invoiceForm.orderPaymentTerm !== 'SPECIFIC_DATE'" label="Credit Days" prop="orderCreditDay" :rules="rules.reqRule">
                <el-input-number
                  v-model="invoiceForm.orderCreditDay"
                  :disabled="(invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && Object.keys(orderSelected).length === 0) || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && Object.keys(orderSelected).length === 0) || invoiceForm.orderPaymentTerm === 'CASH_IN_ADVANCE'"
                  :controls="false" :precision="0" :min="0"
                >
                </el-input-number>
              </el-form-item>
              <el-form-item v-else label="Payment Term Date" prop="orderPaymentTermDate" :rules="rules.reqRule">
                <el-date-picker
                  :disabled="(invoiceForm.invoiceType === INVOICE_TYPE.PROFORMA && Object.keys(orderSelected).length === 0) || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && Object.keys(orderSelected).length === 0)"
                  type="date" format="dd-MM-yyyy"
                  v-model="invoiceForm.orderPaymentTermDate"
                />
              </el-form-item>

            </div>
          </div>
        </div>
        <div class="basic-card-block" v-if="isSellerView">
          <h1>Customer Information</h1>
          <div>
            <div class="div-frac-14">
              <el-form-item v-if="creditAssociationList" label="Company Name" prop="buyerSysOrganizationId" :rules="rules.reqRule">
                <el-select
                  filterable
                  v-model="invoiceForm.buyerSysOrganizationId"
                  value-key="id"
                  @change="handleCompanyNameSelect"
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE || propsView === INVOICE_VIEW.EDIT_INVOICE"
                >
                  <el-option
                    v-for="item in creditAssociationList"
                    :key="item.id" :label="item.counterpartyCompanyName" :value="item.counterpartySysOrganizationId"
                    :disabled="item.status !== 'ACTIVE'"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="Customer Name" prop="buyerId" :rules="rules.reqRule">
                <el-select
                  filterable
                  v-model="invoiceForm.buyerId"
                  value-key="id"
                  @change="handleBuyerNameSelect"
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                >
                  <el-option
                    v-for="item in customerList"
                    :key="item.id" :label="item.name" :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="Email Address" prop="buyerEmail" :rules="rules.emaileRule">
                <el-input v-model="invoiceForm.buyerEmail" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
              <el-form-item label="Country" prop="buyerCountry" :rules="rules.reqRule">
                <country-selector
                  v-model="invoiceForm.buyerCountry"
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                  >
                </country-selector>
              </el-form-item>
            </div>
            <div class="div-frac-14">
              <el-form-item label="Building Name" prop="buyerBuildingName">
                <el-input v-model="invoiceForm.buyerBuildingName" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
              <el-form-item label="Address Line 1" prop="buyerAddressLine1" :rules="rules.reqRule">
                <el-input v-model="invoiceForm.buyerAddressLine1" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
              <el-form-item label="Address Line 2" prop="buyerAddressLine2">
                <el-input v-model="invoiceForm.buyerAddressLine2" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
              <el-form-item label="Postal Code" prop="buyerPostalCode" :rules="rules.reqRule">
                <el-input v-model="invoiceForm.buyerPostalCode" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="basic-card-block" v-else>
          <h1>Seller Information</h1>
          <div>
            <div class="div-frac-12">
              <el-form-item v-if="creditAssociationList" label="Company Name" prop="sellerSysOrganizationId" :rules="rules.reqRule">
                <el-select
                  filterable
                  v-model="invoiceForm.sellerSysOrganizationId"
                  value-key="id"
                  @change="handleCompanyNameSelect"
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE || propsView === INVOICE_VIEW.EDIT_INVOICE"
                >
                  <el-option
                    v-for="item in creditAssociationList"
                    :key="item.id" :label="item.counterpartyCompanyName" :value="item.counterpartySysOrganizationId"
                    :disabled="item.status !== 'ACTIVE'"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="Seller Name" prop="sellerId" :rules="rules.reqRule">
                <el-select
                  filterable
                  v-model="invoiceForm.sellerId"
                  value-key="id"
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                >
                  <el-option
                    v-for="item in customerList"
                    :key="item.id" :label="item.name" :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="basic-card-block">
            <h1>Item</h1>
          <div class="div-item-info-body">
            <el-button
              v-if="!isCreatedWithOrder && isSellerView && !isViewOnly"
              type="primary" round size="mini"
              @click="addInvoiceItem"
              :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
            >
              Add
            </el-button>
            <el-button
              v-else-if="!isViewOnly"
              :disabled="(Object.keys(orderSelected).length === 0) || propsView === INVOICE_VIEW.VIEW_INVOICE"
              outline round size="mini"
              @click="dialogSelectInvoiceItems = true"
            >
              Add
            </el-button>
            <el-table class="el-table-invoice-items-cdNotes" :data="invoiceForm.invoiceItems" border :loading="invoiceItemDocLoading" :header-cell-style="{ color: '#909399' }" :cell-style="{alignSelf: 'center', padding: '0', height: '80px'}">
              <el-table-column prop="itemType" min-width="150px">
                <template v-slot:header><span>* </span>Item Type</template>
                <template v-slot="scope">
                  <el-form-item :prop="`invoiceItems.${scope.$index}.itemType`" :rules="rules.reqRule">
                    <el-select
                      :disabled="isCreatedWithOrder || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL)"
                      v-model="scope.row.itemType"
                      @change="handleInvoiceItemTypeSelect(scope.$index)"
                    >
                      <el-option
                        v-for="item in ( isCommissionInvoice ? ORDER_ITEM_TYPE_OPTIONS : ORDER_ITEM_TYPE_OPTIONS.filter(d => d.value !== 'COMMISSION'))"
                        :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="description" min-width="320px">
                <template v-slot:header><span>* </span>Description</template>
                <template v-slot="scope">
                  <el-form-item :prop="`invoiceItems.${scope.$index}.description`" :rules="!scope.row.itemType ? rules.notReqRule : rules.reqRule">
                    <el-select
                      v-if="scope.row.itemType !== ORDER_ITEM_TYPE.PRODUCT"
                      :disabled="!scope.row.itemType || isCreatedWithOrder || propsView === INVOICE_VIEW.VIEW_INVOICE || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL)"
                      v-model="scope.row.description"
                    >
                      <el-option
                        v-for="item in ORDER_OTHER_COST_DESCRIPTION_OPTIONS"
                        :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                    <el-input v-else-if="(scope.row.itemType === ORDER_ITEM_TYPE.PRODUCT && isCreatedWithOrder) || (!isSellerView && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL)" v-model="scope.row.description" disabled/>
                    <el-cascader
                      v-model="scope.row.descriptionName"
                      :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                      v-else-if="scope.row.itemType === ORDER_ITEM_TYPE.PRODUCT && !isCreatedWithOrder"
                      :options="productAndSpecificationOptions"
                      @change="handleItemDescriptionCascaderChange($event, scope.$index)"
                      :props="{ key: 'id', value: 'id', label: 'name', children: 'specifications', expandTrigger: 'hover' }"
                      ></el-cascader>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="supplyDate" label="Supply Date" min-width="150px" v-if="invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && !isCommissionInvoice">
                <template v-slot:header><span>* </span>Supply Date</template>
                <template v-slot="scope">
                  <el-form-item :prop="`invoiceItems.${scope.$index}.supplyDate`" :rules="(invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL) ? rules.reqRule : rules.notReqRule">
                    <el-date-picker
                      :disabled="(isCreatedWithOrder && isSellerView) || propsView === 'VIEW_INVOICE'"
                      v-model="scope.row.supplyDate"
                      type="date" format="dd-MM-yyyy"
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="" label="Unit Metric" min-width="100px" v-if="!isCommissionInvoice">
                <template v-slot="scope">
                  <el-form-item :prop="`invoiceItems.${scope.$index}.unit`">
                    <el-select
                      disabled
                      v-model="scope.row.unit"
                    >
                      <el-option
                        v-for="item in UNIT_OPTIONS"
                        :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="" label="Quantity" min-width="100px" v-if="!isCommissionInvoice">
                <template v-slot:header ><span>* </span>Quantity</template>
                <template v-slot="scope">
                  <el-form-item :prop="`invoiceItems.${scope.$index}.quantity`" :rules="rules.reqGreaterThanZeroRule">
                    <el-input-number
                      :disabled="(isCreatedWithOrder && isSellerView) || propsView === INVOICE_VIEW.VIEW_INVOICE"
                      :controls="false" :precision="3" :min="0"
                      v-model="scope.row.quantity"
                      @blur="calculateInvoiceValues"
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="" label="Price" min-width="100px" v-if="!isCommissionInvoice">
                 <template v-slot:header><span>* </span>Price</template>
                <template v-slot="scope">
                  <el-form-item :prop="`invoiceItems.${scope.$index}.price`" :rules="rules.reqGreaterThanZeroRule">
                    <el-input-number
                      :disabled="(isCreatedWithOrder && isSellerView) || propsView === INVOICE_VIEW.VIEW_INVOICE"
                      :controls="false" :precision="3" :min="0"
                      v-model="scope.row.price"
                      @blur="calculateInvoiceValues"
                    />
                    </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="" min-width="120px">
                <template v-slot:header><span>* </span>Amount</template>
                <template v-slot="scope">
                  <el-form-item  :prop="`invoiceItems.${scope.$index}.amount`" :rules="rules.reqGreaterThanZeroRule">
                    <el-input-number
                      :disabled="true"
                      :controls="false" :precision="3" :min="0"
                      v-model="scope.row.amount"
                      @blur="calculateInvoiceValues"
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="gstType" min-width="200px">
                <template v-slot:header><span>* </span>GST Type</template>
                <template v-slot="scope">
                  <el-form-item :prop="`invoiceItems.${scope.$index}.gstType`" :rules="rules.reqRule">
                    <el-select
                      :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                      v-model="scope.row.gstType"
                      @change="handleGstTypeSelect(scope.$index, scope.row.gstType)"
                    >
                      <el-option
                        v-for="item in GST_TYPE_OPTIONS"
                        :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="gstPercentage" min-width="100px">
                <template v-slot:header><span>* </span>GST %</template>
                <template v-slot="scope">
                  <el-form-item :prop="`invoiceItems.${scope.$index}.gstPercentage`">
                    <el-input-number
                      disabled
                      :controls="false" :precision="2" :min="0"
                      v-model="scope.row.gstPercentage"
                      @blur="calculateInvoiceValues"
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="Receipt No." prop="receiptNo" v-if="!isCreatedWithOrder" min-width="250px">
                <template v-slot="scope">
                  <el-form-item :prop="`invoiceItems.${scope.$index}.receiptNo`" :rules="scope.row.itemType === ORDER_ITEM_TYPE.PRODUCT ? rules.reqRule : rules.notReqRule">
                    <el-input
                      :disabled="scope.row.itemType !== ORDER_ITEM_TYPE.PRODUCT || propsView === INVOICE_VIEW.VIEW_INVOICE || (isCreatedWithOrder && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && isSellerView)"
                      v-model="scope.row.receiptNo"
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column class="el-table-column-item-bdn-doc" label="BDN Document" v-if="isCreatedWithOrder && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && invoiceForm.orderType !== ORDER_TYPE.EX_WHARF" min-width="150px">
                <template v-slot="scope">
                  <div v-if="scope.row.jobs && scope.row.jobs.length > 0">
                    <div v-for="job in scope.row.jobs" :key="job.jobId">
                      <el-button type="primary" plain v-if="scope.row.itemType === ORDER_ITEM_TYPE.PRODUCT" @click="previewFile(job.bdnPdfId)">{{ job.docNumber }}</el-button>
                      <p v-else>-</p>
                    </div>
                  </div>
                  <div v-else style="text-align: center">-</div>
                </template>
              </el-table-column>
              <el-table-column v-else-if="isCreatedWithOrder && invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && invoiceForm.orderType === ORDER_TYPE.EX_WHARF" label="Loading Documents" width="200">
                <template slot-scope="scope">
                  <div v-if="scope.row.jobs && scope.row.jobs.length > 0">
                    <div v-for="job in scope.row.jobs" :key="job.jobId">
                      <el-button type="primary" plain @click="previewFile(job.cqPdfId)">{{`[${job.docNumber}] CQ File`}}</el-button>
                      <el-button type="primary" plain @click="previewFile(job.coqPdfId)" style="margin: 0">{{`[${job.docNumber}] COQ File`}}</el-button>
                    </div>
                  </div>
                  <div v-else style="text-align: center">-</div>
                </template>
              </el-table-column>
              <el-table-column label="" width="50px" fixed="right">
                <template v-slot="scope">
                    <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="removeInvoiceItem(scope.row, scope.$index)" :disabled="invoiceForm.invoiceItems.length === 1 || propsView === INVOICE_VIEW.VIEW_INVOICE"/>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="basic-card-block">
          <h1>Payment Information</h1>
          <div>
            <div class="div-frac-12">
              <el-form-item label="Exchange Currency" prop="exchangeCurrency" :rules="rules.reqRule">
                <el-select v-model="invoiceForm.exchangeCurrency" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE">
                  <el-option
                    v-for="item in currencyList"
                    :key="item.id" :label="item.code" :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Exchange Currency Rate" prop="exchangeCurrencyRate" :rules="rules.reqGreaterThanZeroRule">
                <el-input-number
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                  :controls="false" :precision="3" :min="0"
                  v-model="invoiceForm.exchangeCurrencyRate"
                  @blur="calculateInvoiceValues"
                >
                </el-input-number>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="basic-card-block">
          <h1>Bank Information</h1>
          <div>
            <div class="div-bank-info-title-hr">
              <p>Chaser Bank</p>
              <hr/>
            </div>
            <div :class="isSellerView ? 'div-bank-info-frac-13 div-bank-info-body' : 'div-frac-13 div-bank-info-body'">
              <el-button
                v-if="isSellerView"
                round outline size="small"
                :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                @click="dialogChaserBank = true"
              >
                Select Bank
              </el-button>
              <el-form-item
                label="Name of Bank"
                prop="sellerChaserBankName">
                <el-input v-model="invoiceForm.sellerChaserBankName" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
              <el-form-item
                label="Swift Code"
                prop="sellerChaserBankSwiftCode">
                <el-input v-model="invoiceForm.sellerChaserBankSwiftCode" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
              <el-form-item
                label="Country"
                prop="sellerChaserBankCountry">
                <country-selector
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                  v-model="invoiceForm.sellerChaserBankCountry"
                >
                </country-selector>
              </el-form-item>
            </div>
            <div class="div-bank-info-title-hr">
              <p>Company Bank</p>
              <hr/>
            </div>
            <div :class="isSellerView ? 'div-bank-info-frac-13 div-bank-info-body' : 'div-frac-13 div-bank-info-body'">
              <el-button
                v-if="isSellerView"
                round outline size="small"
                :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                @click="dialogCompanyBank = true"
              >
                Select Bank
              </el-button>
              <el-form-item label="Name of Bank" prop="sellerBankName" :rules="isSellerView ? rules.reqRule : rules.notReqRule">
                <el-input v-model="invoiceForm.sellerBankName" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
              <el-form-item label="Swift Code" prop="sellerBankSwiftCode" :rules="isSellerView ? rules.reqRule : rules.notReqRule">
                <el-input v-model="invoiceForm.sellerBankSwiftCode" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
              <el-form-item label="Country" prop="sellerBankCountry" :rules="isSellerView ? rules.reqRule : rules.notReqRule">
                <country-selector
                  v-model="invoiceForm.sellerBankCountry"
                  :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"
                >
                </country-selector>
              </el-form-item>
            </div>
            <div :class="isSellerView ? 'div-bank-info-frac-13 div-bank-info-body' : 'div-frac-13 div-bank-info-body'">
              <div v-if="isSellerView"/>
              <el-form-item label="Account Name" prop="sellerBankAccountName" :rules="isSellerView ? rules.reqRule : rules.notReqRule">
                <el-input v-model="invoiceForm.sellerBankAccountName" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
              <el-form-item label="Account Number" prop="sellerBankAccountNo" :rules="isSellerView ? rules.reqRule : rules.notReqRule">
                <el-input v-model="invoiceForm.sellerBankAccountNo" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
              <el-form-item label="Account Type" prop="sellerBankAccountType" :rules="isSellerView ? rules.reqRule : rules.notReqRule">
                <el-input v-model="invoiceForm.sellerBankAccountType" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
            </div>
            <div :class="isSellerView ? 'div-bank-info-frac-1 div-bank-info-body' : 'div-frac-1 div-bank-info-body'">
              <div/>
              <el-form-item label="Banking Terms and Conditions" prop="sellerBankBankingTermsConditions">
                <el-input v-model="invoiceForm.sellerBankBankingTermsConditions" :disabled="propsView === INVOICE_VIEW.VIEW_INVOICE"/>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="basic-card-block" ref="cdNoteDiv" v-if="(propsView === INVOICE_VIEW.EDIT_INVOICE || propsView === INVOICE_VIEW.VIEW_INVOICE) && hasPermission($permissionFunctionCode.EDIT)
        && (isSellerView && [INVOICE_STATUS.SENT, INVOICE_STATUS.PAID, INVOICE_STATUS.OUTSTANDING].includes(invoiceForm.sellerInvoiceStatus)
          || (!isSellerView && invoiceForm.createdBySysOrganization === myCompanyId && [INVOICE_STATUS.AUTHORIZED_SIGNATORY_APPROVED, INVOICE_STATUS.PAID, INVOICE_STATUS.OUTSTANDING].includes(invoiceForm.sellerInvoiceStatus)))">
          <h1>Credit/Debit Note</h1>
          <div>
            <div class="div-cd-note-info-body">
              <h4>Credit Note</h4>
              <el-button outline round size="small"
                @click="isCreditNote = true, dialogSelectCdNote = true" :disabled="!isApplyCdNote"
              >
                Add
              </el-button>
              <el-table :data="invoiceForm.creditNotes" border :header-cell-style="{ color: '#909399' }">
                <el-table-column label="Invoice Number">
                  <template v-slot="scope">
                    <el-form-item>
                      <el-input v-if="scope.row.createdByInvoice" v-model="scope.row.createdByInvoice.userEnteredInvoiceNo" :disabled="true"/>
                      <el-input v-else :disabled="true"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="Date Created">
                  <template v-slot="scope">
                    <el-form-item>
                      <el-date-picker
                        :disabled="true"
                        type="date"
                        format="dd-MM-yyyy"
                        v-model="scope.row.noteDate"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="Note Total">
                  <template v-slot="scope">
                    <el-form-item>
                      <el-input-number
                        :disabled="true"
                        :controls="false" :precision="3" :min="null"
                        v-model="scope.row.noteTotal"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="" width="50px">
                  <template v-slot="scope">
                    <el-button
                      type="danger" icon="el-icon-delete" size="mini" circle
                      :disabled="!isApplyCdNote"
                      @click="removeCdNoteItem(scope.$index, true)"
                    />
                  </template>
                </el-table-column>
              </el-table>
              <h4 class="h4-debit-note">Debit Note</h4>
              <el-button outline round size="small"
                @click="isCreditNote = false, dialogSelectCdNote = true" :disabled="!isApplyCdNote"
              >
                Add
              </el-button>
              <el-table :data="invoiceForm.debitNotes" border :header-cell-style="{ color: '#909399' }">
                <el-table-column label="Invoice Number">
                  <template v-slot="scope">
                    <el-form-item>
                      <el-input
                        :disabled="true"
                        v-model="scope.row.createdByInvoice.userEnteredInvoiceNo"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="Date Created">
                  <template v-slot="scope">
                    <el-form-item>
                      <el-date-picker
                        :disabled="true"
                        type="date"
                        format="dd-MM-yyyy"
                        v-model="scope.row.noteDate"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="Amount">
                  <template v-slot="scope">
                    <el-form-item>
                      <el-input-number
                        :disabled="true"
                        v-model="scope.row.amount"
                        :controls="false" :precision="3" :min="null"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="" width="50px">
                  <template v-slot="scope">
                    <el-button
                      type="danger" icon="el-icon-delete" size="mini" circle
                      :disabled="!isApplyCdNote"
                      @click="removeCdNoteItem(scope.$index, false)"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-form>
      <div class="div-form-main-right-summary">
        <div>
          <div class="div-invoice-payment-summary-main">
            <h4 v-if="propsView === INVOICE_VIEW.EDIT_INVOICE || propsView === INVOICE_VIEW.VIEW_INVOICE">Invoice Number</h4>
            <div class="div-invoice-payment-summary-invoice-no" v-if="propsView === INVOICE_VIEW.EDIT_INVOICE || propsView === INVOICE_VIEW.VIEW_INVOICE">
              <h1>{{ `${invoiceForm.userEnteredInvoiceNo} ${invoiceForm.invoiceVersionNo ? `(${invoiceForm.invoiceVersionNo})` : ''}` }}</h1>
              <i class="el-icon-document" @click="previewFile(invoiceForm.invoiceFileId)"/>
            </div>
            <div class="div-invoice-payment-summary-top">
              <div v-if="propsView === INVOICE_VIEW.EDIT_INVOICE || propsView === INVOICE_VIEW.VIEW_INVOICE">
                <p>Invoice Status</p>
                <div  class="status-tag" :style="getStatusTagStyle(isSellerView ? invoiceForm.sellerInvoiceStatus : invoiceForm.buyerInvoiceStatus)">
                  {{isSellerView ? capitalizeFirstLetter(invoiceForm.sellerInvoiceStatus, '_') : capitalizeFirstLetter(invoiceForm.buyerInvoiceStatus, '_')}}
                </div>
              </div>
            </div>
            <h4>Invoice Summary</h4>
            <div class="div-invoice-payment-currency">
              <p>Payment Currency: {{invoiceForm.orderCurrency || 'N.A.'}}</p>
            </div>
            <div class="div-invoice-payment-summary" v-if="propsView === INVOICE_VIEW.CREATE_INVOICE">
              <div>
                <p>Subtotal</p>
                <div><hr/></div>
                <p class="p-invoice-summary-amt" v-if="invoicePaymentInfo.subTotal">{{`${invoiceForm.orderCurrency} ${numberWithCommas(invoicePaymentInfo.subTotal, 2)}`}}</p>
                <p v-else class="p-invoice-summary-amt">{{invoiceForm.orderCurrency}} 0.00</p>
              </div>
              <div>
                <p>Tax</p>
                <div><hr/></div>
                <p class="p-invoice-summary-amt" v-if="invoicePaymentInfo.taxTotal">{{`${invoiceForm.orderCurrency} ${numberWithCommas(invoicePaymentInfo.taxTotal, 2)}`}}</p>
                <p v-else class="p-invoice-summary-amt">{{invoiceForm.orderCurrency}} 0.00</p>
              </div>
              <div>
                <p style="font-weight: 500;">Invoice Total</p>
                <div><hr style="border-top: 2px dotted grey;"/></div>
                <p v-if="invoicePaymentInfo && invoicePaymentInfo.invoiceTotal" style="font-weight: 500;">{{`${invoiceForm.orderCurrency} ${numberWithCommas(invoicePaymentInfo.invoiceTotal, 2)}`}}</p>
                <p v-else style="font-weight: 500;">{{invoiceForm.orderCurrency}} 0.00</p>
              </div>
            </div>
            <div class="div-invoice-payment-summary" v-if="propsView === INVOICE_VIEW.EDIT_INVOICE || propsView === INVOICE_VIEW.VIEW_INVOICE">
              <div>
                <p>Invoice Total</p>
                <div><hr/></div>
                <p class="p-invoice-summary-amt" v-if="invoicePaymentReceiptSettlement && invoicePaymentReceiptSettlement.invoiceTotal">
                  {{`${invoiceForm.orderCurrency} ${numberWithCommas(invoicePaymentReceiptSettlement.invoiceTotal, 2)}`}}
                </p>
                <p v-else class="p-invoice-summary-amt">{{invoiceForm.orderCurrency}} 0.00</p>
              </div>
              <div>
                <p>Total Amount {{isSellerView ? 'Receivable' : 'Payable' }}</p>
                <div><hr/></div>
                <p class="p-invoice-summary-amt" v-if="invoicePaymentReceiptSettlement && invoicePaymentReceiptSettlement.totalAmountReceivable && isSellerView">
                  {{`${invoiceForm.orderCurrency} ${numberWithCommas(invoicePaymentReceiptSettlement.totalAmountReceivable, 2)}`}}
                </p>
                <p class="p-invoice-summary-amt" v-else-if="invoicePaymentReceiptSettlement && invoicePaymentReceiptSettlement.totalAmountPayable && !isSellerView">
                  {{`${invoiceForm.orderCurrency} ${numberWithCommas(invoicePaymentReceiptSettlement.totalAmountPayable, 2)}`}}
                </p>
                <p v-else class="p-invoice-summary-amt">{{invoiceForm.orderCurrency}} 0.00</p>
              </div>
              <div>
                <p>Outstanding Amount</p>
                <div><hr/></div>
                <p class="p-invoice-summary-amt" v-if="invoicePaymentReceiptSettlement && invoicePaymentReceiptSettlement.receivableOutstandingAmount && isSellerView">
                  {{`${invoiceForm.orderCurrency} ${numberWithCommas(invoicePaymentReceiptSettlement.receivableOutstandingAmount, 2)}`}}
                </p>
                <p class="p-invoice-summary-amt" v-else-if="invoicePaymentReceiptSettlement && invoicePaymentReceiptSettlement.receivableOutstandingAmount && !isSellerView">
                  {{`${invoiceForm.orderCurrency} ${numberWithCommas(invoicePaymentReceiptSettlement.payableOutstandingAmount, 2)}`}}
                </p>
                <p v-else class="p-invoice-summary-amt">{{invoiceForm.orderCurrency}} 0.00</p>
              </div>
            </div>
            <div class="div-invoice-payment-summary-btm" v-if="propsView === INVOICE_VIEW.CREATE_INVOICE">
              <hr style="border-top: 1px solid #F4F4F4;"/>
              <div class="div-invoice-payment-currency">
                <p>Exchange Currency: {{invoiceForm.exchangeCurrency || 'N.A.'}} (for GST purpose)</p>
              </div>
              <div class="div-invoice-payment-summary" v-if="propsView === INVOICE_VIEW.CREATE_INVOICE">
                <div>
                  <p>Total Amount before GST</p>
                  <div><hr/></div>
                  <p class="p-invoice-summary-amt" v-if="invoicePaymentInfo.totalAmountBeforeGst">{{`${invoiceForm.exchangeCurrency} ${numberWithCommas(invoicePaymentInfo.totalAmountBeforeGst, 2)}`}}</p>
                  <p v-else class="p-invoice-summary-amt">{{invoiceForm.exchangeCurrency}} 0.00</p>
                </div>
                <div>
                  <p>GST Amount</p>
                  <div><hr/></div>
                  <p class="p-invoice-summary-amt" v-if="invoicePaymentInfo.gstAmount">{{`${invoiceForm.exchangeCurrency} ${numberWithCommas(invoicePaymentInfo.gstAmount, 2)}`}}</p>
                  <p v-else class="p-invoice-summary-amt">{{invoiceForm.exchangeCurrency}} 0.00</p>
                </div>
                <div>
                  <p>Total Amount after GST</p>
                  <div><hr/></div>
                  <p class="p-invoice-summary-amt" v-if="invoicePaymentInfo.totalAmountAfterGst">{{`${invoiceForm.exchangeCurrency} ${numberWithCommas(invoicePaymentInfo.totalAmountAfterGst, 2)}`}}</p>
                  <p v-else class="p-invoice-summary-amt">{{invoiceForm.exchangeCurrency}} 0.00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dialog-select-order :isSellerView="isSellerView" :isCommissionInvoice="isCommissionInvoice" :dialogSelectOrder="dialogSelectOrder" :orderType="invoiceForm.orderType"
      @handleOrderDialogClose="dialogSelectOrder = false" @handleConfirmOrderClick="handleConfirmOrderClick" :orderSelectedId="invoiceForm.orderId"
    />
    <dialog-select-invoice-items :isCommissionInvoice="isCommissionInvoice" :dialogSelectInvoiceItems="dialogSelectInvoiceItems" :order="orderSelected" :propsView="propsView"
      :selectedItems="invoiceForm.invoiceItems || []" :invoiceType="invoiceForm.invoiceType" :isSellerView="isSellerView"
      @handleConfirmInvoiceItemsClick="handleConfirmInvoiceItemsClick" @handleInvoiceItemsDialogClose="dialogSelectInvoiceItems = false"
    />
    <dialog-select-cd-note :buyerSysOrganizationId="invoiceForm.buyerSysOrganizationId" :isCreditNote="isCreditNote" :dialogSelectCdNote="dialogSelectCdNote" :propsCreditNoteSelected="invoiceForm.creditNotes" :propsDebitNoteSelected="invoiceForm.debitNotes"
      @handleSelectCdNoteDialogClose="dialogSelectCdNote = false" @handleConfirmCdNoteDialogClick="handleConfirmCdNoteDialogClick" :appliedNotes="invoiceForm.appliedNotes"
    />
    <el-dialog class="el-dialog-update-bank" :visible="dialogCompanyBank || dialogChaserBank" width="90%" @close="dialogCompanyBank ? dialogCompanyBank = false : dialogChaserBank = false">
      <update-bank
        v-if="dialogCompanyBank || dialogChaserBank"
        isViewOnly
        hasButtons
        hasRadio
        :refKey="dialogCompanyBank ? 'companyBank' : 'chaserBank'"
        :category="dialogCompanyBank ? 'COMPANY_BANK' : 'CHASER_BANK'"
        :sysOrganizationId="myCompanyId"
        :data="bankList.filter(item => item.bankType === (dialogCompanyBank ? 'COMPANY_BANK' : 'CHASER_BANK'))"
        @handleConfirmBankClick="handleConfirmBankClick"
        @handleCloseBankDialog="dialogCompanyBank ? dialogCompanyBank = false : dialogChaserBank = false"
        >
      </update-bank>
    </el-dialog>
  </div>
</template>
<script>
import {
  UNIT_OPTIONS, PAYMENT_TERM_OPTIONS, GST_TYPE_OPTIONS,
  INVOICE_STATUS, INVOICE_TYPE, INVOICE_TYPE_OPTIONS, INVOICE_VIEW,
  ORDER_TYPE, ORDER_ITEM_TYPE, ORDER_ITEM_TYPE_OPTIONS, ORDER_OTHER_COST_DESCRIPTION_OPTIONS
} from '@/constants.js'
import { getStatusTagStyle, sortByAlphabetical, numberWithCommas, capitalizeFirstLetter, openBlobUrlInNewTab } from '@/utils'
import CountrySelector from '@/components/CountrySelector'
import DialogSelectOrder from './DialogSelectOrder'
import DialogSelectInvoiceItems from './DialogSelectInvoiceItems'
import { mapState } from 'vuex'
import DialogSelectCdNote from './DialogSelectCdNote'
import { getOrderById } from '@/services/modules/order.js'
import { getInvoiceById, createInvoice, updateInvoice, deleteInvoice, cancelInvoice, addRemoveInvoice, verifyInvoiceCA } from '@/services/modules/invoice.js'
import UpdateBank from '@/views/systemManager/companyManager/components/UpdateBank.vue'
import { getUsersByCompanyId } from '@/services/modules'
import { getFileBlobUrl } from '@/services/modules/comm.js'

export default {
  name: 'InvoiceForm',
  components: {
    CountrySelector,
    DialogSelectOrder,
    DialogSelectInvoiceItems,
    DialogSelectCdNote,
    UpdateBank
  },
  props: {
    data: Object,
    orderType: String,
    propsView: String,
    invoiceId: String,
    isSellerView: Boolean,
    isViewOnly: Boolean
  },
  data () {
    const rules = {
      reqRule: [{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }],
      reqGreaterThanZeroRule: [{ required: true, validator: this.reqGreaterThanZeroValidation, trigger: ['blur'] }],
      emaileRule: [{ required: true, validator: this.emailValidation, trigger: ['blur'] }],
      notReqRule: [{ required: false }]
    }
    const invoiceForm = {
      fileList: [],
      invoiceFileId: null,
      invoiceDate: null,
      invoiceDueDate: null,
      invoiceType: INVOICE_TYPE.COMMERCIAL,
      orderNo: null,
      sellerName: null,
      orderTerminal: null,
      orderVesselName: null,
      orderVesselImo: null,
      orderVesselShipId: null,
      orderPort: 'Singapore',
      orderLocation: null,
      orderCurrency: 'USD',
      orderPaymentTerm: null,
      orderCreditDay: null,
      orderPaymentTermDate: null,
      buyerSysOrganizationId: null,
      buyerId: null,
      buyerEmail: null,
      buyerCountry: null,
      buyerBuildingName: null,
      buyerAddressLine1: null,
      buyerAddressLine2: null,
      buyerPostalCode: null,
      exchangeCurrency: 'SGD',
      exchangeCurrencyRate: null,
      invoiceItems: [{
        itemType: null,
        description: null,
        quantity: null,
        price: null,
        unit: 'MT',
        supplyDate: null,
        amount: null,
        orderProductId: null,
        orderOtherCostId: null,
        receiptNo: null,
        gstType: null,
        gstPercentage: null
      }],
      sellerSysOrganizationId: null,
      sellerId: null,
      sellerChaserBankName: null,
      sellerChaserBankSwiftCode: null,
      sellerChaserBankCountry: null,
      sellerBankName: null,
      sellerBankSwiftCode: null,
      sellerBankAccountNo: null,
      sellerBankAccountName: null,
      sellerBankAccountType: null,
      sellerBankCountry: null,
      sellerBankBankingTermsConditions: null,
      creditNotes: [],
      debitNotes: []
    }
    const invoicePaymentInfo = {
      totalAmountBeforeGst: null,
      totalAmountAfterGst: null,
      gstAmount: null,
      subTotal: null,
      taxTotal: null,
      invoiceTotal: null
    }
    return {
      INVOICE_STATUS,
      INVOICE_TYPE,
      INVOICE_VIEW,
      ORDER_ITEM_TYPE,
      PAYMENT_TERM_OPTIONS: Object.freeze(PAYMENT_TERM_OPTIONS),
      GST_TYPE_OPTIONS: Object.freeze(GST_TYPE_OPTIONS),
      INVOICE_TYPE_OPTIONS: Object.freeze(INVOICE_TYPE_OPTIONS),
      ORDER_ITEM_TYPE_OPTIONS: Object.freeze(ORDER_ITEM_TYPE_OPTIONS),
      UNIT_OPTIONS: Object.freeze(UNIT_OPTIONS),
      ORDER_OTHER_COST_DESCRIPTION_OPTIONS: Object.freeze(ORDER_OTHER_COST_DESCRIPTION_OPTIONS),
      ORDER_TYPE,
      rules,
      dialogSelectOrder: false,
      dialogSelectInvoiceItems: false,
      invoiceForm,
      invoicePaymentInfo,
      orderTableData: [],
      customerList: [],
      productAndSpecificationOptions: [],
      orderSelected: {},
      isCreatedWithOrder: false,
      invoicePaymentReceiptSettlement: null,
      isInvoiceFormLoading: true,
      myCompanyId: this.$store.state.currentCompany.id,
      dialogSelectCdNote: false,
      isCreditNote: true,
      isApplyCdNote: false,
      fileUploadUrl: this.$apis.baseUrl + this.$apis.uploadFile,
      dialogCompanyBank: false,
      dialogChaserBank: false,
      isCommissionInvoice: false,
      invoiceItemDocLoading: false
    }
  },
  computed: {
    ...mapState([
      'bankList',
      'chaserDefaultBank',
      'companyDefaultBank',
      'currencyList',
      'currentCompanyUserList',
      'creditAssociationList',
      'locationList',
      'vesselList'
    ])
  },
  created () {
    this.isInvoiceFormLoading = true
    const promises = []
    promises.push(this.$store.dispatch('getBankList'))
    promises.push(this.$store.dispatch('getCurrentCompanyUserList', { orgId: this.$store.state.currentCompany.id }))
    promises.push(this.$store.dispatch('getCreditAssociationList'))
    if (!this.currencyList) { promises.push(this.$store.dispatch('getCurrencyList')) }
    if (!this.locationList) { promises.push(this.$store.dispatch('getLocationList')) }
    if (!this.productList) { promises.push(this.$store.dispatch('getVesselList')) }
    this.getSysProductAndSpecification()
    if (!this.isSellerView) {
      this.isCreatedWithOrder = true
    }
    Promise.all(promises).then(val => {
      /*  Create Invoice from Posted List - Populate Invoice Form */
      if (this.data) {
        getOrderById(this.data.order.id).then(res => {
          const order = res.data
          this.invoiceForm.orderType = order.orderType
          this.orderSelected = order
          this.isCreatedWithOrder = true
          this.invoiceType = INVOICE_TYPE.COMMERCIAL
          this.invoiceForm.orderId = order.id
          this.handleConfirmOrderClick(order, true)
          this.isSellerView ? this.handleCompanyNameSelect(order.buyerSysOrganizationId, order.buyerId).then(() => { this.isInvoiceFormLoading = false })
            : this.handleCompanyNameSelect(order.sellerSysOrganizationId, order.sellerId).then(() => { this.isInvoiceFormLoading = false })
          this.handleConfirmInvoiceItemsClick(this.data.orderItems)
          if (this.companyDefaultBank) {
            this.invoiceForm.sellerBankName = this.companyDefaultBank.name
            this.invoiceForm.sellerBankSwiftCode = this.companyDefaultBank.swiftCode
            this.invoiceForm.sellerBankAccountName = this.companyDefaultBank.accountName
            this.invoiceForm.sellerBankAccountNo = this.companyDefaultBank.accountNo
            this.invoiceForm.sellerBankAccountType = this.companyDefaultBank.accountType
            this.invoiceForm.sellerBankCountry = this.companyDefaultBank.country
          }
          if (this.chaserDefaultBank) {
            this.invoiceForm.sellerChaserBankName = this.chaserDefaultBank.name
            this.invoiceForm.sellerChaserBankSwiftCode = this.chaserDefaultBank.swiftCode
            this.invoiceForm.sellerChaserBankCountry = this.chaserDefaultBank.country
          }
          this.invoiceForm.invoiceDate = new Date()
        })
      /*  View Invoice - Populate Invoice Form */
      } else if (this.propsView === INVOICE_VIEW.VIEW_INVOICE) {
        getInvoiceById(this.invoiceId).then(res => {
          const invoice = res.data
          if (invoice.orderId) {
            this.isCreatedWithOrder = true
            getOrderById(invoice.orderId).then(res => {
              if (res?.code === 1000) {
                this.orderSelected = res.data
                this.invoiceForm.orderType = this.orderSelected.orderType
              }
            })
          } else {
            this.isCreatedWithOrder = false
          }
          this.invoiceForm = invoice
          this.isCommissionInvoice = this.invoiceForm.isCommissionInvoice
          if (this.isCreatedWithOrder && this.invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL) {
            this.populateInvoiceItemDocuments()
          }
          if (this.isSellerView) {
            this.handleCompanyNameSelect(invoice.buyerSysOrganizationId, invoice.buyerId).then(() => { this.isInvoiceFormLoading = false })
          } else {
            this.handleCompanyNameSelect(invoice.sellerSysOrganizationId, invoice.sellerId).then(() => { this.isInvoiceFormLoading = false })
          }
          this.invoiceForm.invoiceItems.forEach((i, index) => {
            if (i.itemType === 'PRODUCT') {
              i.descriptionName = []
              i.descriptionName[0] = i.sysProductId
              i.descriptionName[1] = i.sysProductSpecificationId
            }
          })
          if (invoice.appliedNotes) {
            this.invoiceForm.creditNotes = []
            this.invoiceForm.debitNotes = []
            invoice.appliedNotes.forEach(an => {
              an.noteType === 'CREDIT_NOTE' ? this.invoiceForm.creditNotes.push(an) : this.invoiceForm.debitNotes.push(an)
            })
          }
        })
        /* Populate Invoice Payment Summary */
        this.getPaymentReceiptSettlementById(this.invoiceId)
      } else {
        this.invoiceForm.orderType = this.orderType
        if (this.isSellerView) {
          this.invoiceForm.sellerBankName = this.companyDefaultBank.name
          this.invoiceForm.sellerBankSwiftCode = this.companyDefaultBank.swiftCode
          this.invoiceForm.sellerBankAccountName = this.companyDefaultBank.accountName
          this.invoiceForm.sellerBankAccountNo = this.companyDefaultBank.accountNo
          this.invoiceForm.sellerBankAccountType = this.companyDefaultBank.accountType
          this.invoiceForm.sellerBankCountry = this.companyDefaultBank.country
          this.invoiceForm.sellerChaserBankName = this.chaserDefaultBank.name
          this.invoiceForm.sellerChaserBankSwiftCode = this.chaserDefaultBank.swiftCode
          this.invoiceForm.sellerChaserBankCountry = this.chaserDefaultBank.country
        }
        this.invoiceForm.invoiceDate = new Date()
        this.isInvoiceFormLoading = false
      }
      if (!this.isSellerView && this.invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL) {
        this.invoiceForm.invoiceItems = []
      }
    })
  },
  methods: {
    getStatusTagStyle,
    sortByAlphabetical,
    numberWithCommas,
    capitalizeFirstLetter,
    getFileBlobUrl,
    openBlobUrlInNewTab,
    previewFile (fileId) {
      this.getFileBlobUrl(fileId).then(url => {
        this.openBlobUrlInNewTab(url)
      }).catch(e => {

      })
    },
    handleVesselNameSelect (vesselName) {
      const vessel = this.vesselList.find(d => d.shipName === vesselName)
      this.invoiceForm.orderVesselImo = vessel?.imo
      this.invoiceForm.orderVesselShipId = vessel?.id
    },
    handleVesselImoSelect (vesselImoNo) {
      const vessel = this.vesselList.find(d => d.imo === vesselImoNo)
      this.invoiceForm.orderVesselName = vessel?.shipName
      this.invoiceForm.orderVesselShipId = vessel?.id
    },
    handleConfirmBankClick (bankId) {
      const bankSelected = this.bankList.find(b => b.id === bankId)
      if (this.dialogCompanyBank) {
        this.invoiceForm.sellerBankName = bankSelected.name
        this.invoiceForm.sellerBankSwiftCode = bankSelected.swiftCode
        this.invoiceForm.sellerBankAccountName = bankSelected.accountName
        this.invoiceForm.sellerBankAccountNo = bankSelected.accountNo
        this.invoiceForm.sellerBankAccountType = bankSelected.accountType
        this.invoiceForm.sellerBankCountry = bankSelected.country
        this.dialogCompanyBank = false
      } else {
        this.invoiceForm.sellerChaserBankName = bankSelected.name
        this.invoiceForm.sellerChaserBankSwiftCode = bankSelected.swiftCode
        this.invoiceForm.sellerChaserBankCountry = bankSelected.country
        this.dialogChaserBank = false
      }
    },
    handleInvoiceUploadSuccess () {
      return (res, file, fileList) => {
        if (res.code === 1000) {
          const { data } = res
          fileList[0].id = data.id
          fileList[0].url = data.path
          this.invoiceForm.invoiceFileId = data.id
        }
      }
    },
    handleInvoiceBeforeRemove (file) {
      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.deleteFile,
          data: [file.id]
        }).then(res => {
          this.invoiceForm.invoiceFileId = null
          resolve()
        }).finally(() => {
          resolve()
        })
      })
    },
    handleApplyCdNoteClick () {
      this.isApplyCdNote = true
      this.$refs.cdNoteDiv.scrollIntoView({ behavior: 'smooth' })
    },
    reqGreaterThanZeroValidation (rule, value, callback) {
      if ((value === 0)) {
        callback(new Error('Value must be greater than 0'))
      } else if (!value) {
        callback(new Error('This field is required'))
      } else {
        callback()
      }
    },
    emailValidation (rule, value, callback) {
      if (!value) {
        callback(new Error('This field is required'))
      } else {
        const regex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
        if (!regex.test(value)) {
          callback(new Error('Please enter a valid email address.'))
        } else {
          callback()
        }
      }
    },
    handlePaymentTermChange (val) {
      if (val === 'CASH_IN_ADVANCE' || val === 'SPECIFIC_DATE') {
        this.invoiceForm.orderCreditDay = 0
      }
      if (val === 'SPECIFIC_DATE') {
        this.invoiceForm.orderPaymentTermDate = null
      }
    },
    removeCdNoteItem (index, isCreditNote) {
      isCreditNote ? this.invoiceForm.creditNotes.splice(index, 1) : this.invoiceForm.debitNotes.splice(index, 1)
    },
    handleConfirmCdNoteDialogClick (selection) {
      this.isCreditNote ? this.invoiceForm.creditNotes = selection : this.invoiceForm.debitNotes = selection
      this.dialogSelectCdNote = false
    },
    handleInvoiceFormEditButtonClick () {
      this.$emit('handleInvoiceFormEditButtonClick')
    },
    handleCloseInvoiceForm (action) {
      this.$emit('handleCloseInvoiceForm', action)
    },
    handleInvoiceTypeSelect (invoiceType, isToggleCommInvoice) {
      this.$refs.invoiceForm.resetFields()
      this.orderSelected = {}
      this.invoiceForm.orderId = null
      this.invoiceForm.invoiceItems = []
      if (!isToggleCommInvoice) {
        this.invoiceForm.invoiceType = invoiceType
      } else if (this.isCommissionInvoice) {
        this.invoiceForm.invoiceType = INVOICE_TYPE.COMMERCIAL
      }
      const invoicePaymentInfo = {
        totalAmountBeforeGst: null,
        totalAmountAfterGst: null,
        gstAmount: null,
        subTotal: null,
        taxTotal: null,
        invoiceTotal: null
      }
      this.invoicePaymentInfo = this.$_.cloneDeep(invoicePaymentInfo)
      if (invoiceType === INVOICE_TYPE.COMMERCIAL && this.isSellerView) {
        this.isCreatedWithOrder = false
        this.addInvoiceItem()
      } else {
        this.isCreatedWithOrder = true
      }
    },
    handleInvoiceItemTypeSelect (index) {
      this.invoiceForm.invoiceItems[index].description = null
    },
    handleGstTypeSelect (index, gstType) {
      this.invoiceForm.invoiceItems[index].gstPercentage = gstType === 'S' ? 7 : 0
      this.calculateInvoiceValues()
    },
    getPaymentReceiptSettlementById (id) {
      this.$request.get({ url: `${this.$apis.invoices}/${id}/${this.isSellerView ? 'receiptSettlementSummary' : 'paymentSettlementSummary'}` })
        .then(res => {
          if (res?.code === 1000) {
            this.invoicePaymentReceiptSettlement = res.data
          }
        }).catch(e => {})
    },
    handleConfirmOrderClick (order, isCreated) {
      this.invoiceForm.orderId = order.id
      this.invoiceForm.orderNo = (this.isSellerView || this.isCommissionInvoice) ? order.salesOrderNo : order.purchaseOrderNo
      this.invoiceForm.orderPort = order.port
      if (this.invoiceForm.orderType === ORDER_TYPE.DELIVERED || this.invoiceForm.orderType === ORDER_TYPE.BARGING_NOMINATION) {
        this.invoiceForm.orderVesselName = order.vesselName
        this.invoiceForm.orderVesselImo = order.vesselImoNo
      } else {
        this.invoiceForm.orderTerminal = order.terminal
      }
      this.invoiceForm.orderPaymentTerm = order.paymentTerm
      this.invoiceForm.orderCreditDay = order.creditDay
      this.invoiceForm.orderPaymentTermDate = order.paymentTermDate
      if (this.isSellerView) {
        this.invoiceForm.sellerId = order.sellerId
        this.invoiceForm.buyerSysOrganizationId = order.buyerSysOrganizationId
        if (!isCreated) {
          this.handleCompanyNameSelect(order.buyerSysOrganizationId, order.buyerId)
        }
      } else if (this.isCommissionInvoice) {
        this.invoiceForm.sellerId = order.brokerId
        this.invoiceForm.sellerSysOrganizationId = order.brokerSysOrganizationId
        this.invoiceForm.buyerId = order.sellerId
        this.handleCompanyNameSelect(order.brokerSysOrganizationId, order.brokerId)
      } else {
        this.invoiceForm.buyerId = order.buyerId
        this.invoiceForm.sellerSysOrganizationId = order.sellerSysOrganizationId
        if (!isCreated) {
          this.handleCompanyNameSelect(order.sellerSysOrganizationId, order.sellerId)
        }
      }
      this.orderSelected = order
      this.dialogSelectOrder = false
    },
    handleConfirmInvoiceItemsClick (invoiceItems) {
      this.invoiceForm.invoiceItems = []
      if (!this.isCommissionInvoice) {
        invoiceItems.forEach(d => {
          const invoiceFormInvoiceItem = {
            sysProductId: d.sysProductId,
            sysProductSpecificationId: d.sysProductSpecificationId,
            itemType: d.itemType,
            description: d.displayName || d.description,
            quantity: d.itemType === ORDER_ITEM_TYPE.PRODUCT ? (d.verifiedQuantity ? d.verifiedQuantity : (d.finalQuantity ? d.finalQuantity : d.maximumQuantity)) : d.quantity,
            price: d.itemType === ORDER_ITEM_TYPE.OTHER_COST ? d.unitPrice
              : (this.invoiceForm.invoiceType === INVOICE_TYPE.COMMERCIAL && this.isSellerView ? d.finalBillingPrice : d.price),
            unit: d.unitMetric || d.unit,
            supplyDate: d.supplyDate,
            orderProductId: d.itemType === ORDER_ITEM_TYPE.PRODUCT ? d.id : null,
            orderOtherCostId: d.itemType === ORDER_ITEM_TYPE.OTHER_COST ? d.id : null,
            amount: d.itemType === ORDER_ITEM_TYPE.PRODUCT ? null : d.cost,
            receiptNo: null,
            gstType: d.gstType,
            gstPercentage: d.gstPercentage
          }
          this.invoiceForm.invoiceItems.push(invoiceFormInvoiceItem)
        })
        this.calculateInvoiceValues()
        this.populateInvoiceItemDocuments()
      } else {
        invoiceItems.forEach(d => {
          const invoiceFormInvoiceItem = {
            orderCommissionId: d.id,
            itemType: 'COMMISSION',
            description: `${d.currency} ${this.numberWithCommas(d.fee, 2)} P${d.uomType}`,
            amount: d.amount
          }
          this.invoiceForm.invoiceItems.push(invoiceFormInvoiceItem)
        })
      }
      this.dialogSelectInvoiceItems = false
    },
    async handleCompanyNameSelect (companyId, buyerSellerId) {
      try {
        if (this.isSellerView) {
          this.invoiceForm.buyerId = null
          this.invoiceForm.buyerEmail = null
        } else {
          this.invoiceForm.sellerId = null
          this.invoiceForm.sellerEmail = null
        }
        const creditAssociation = this.creditAssociationList.find(item => item.counterpartySysOrganizationId === companyId) || {}
        Promise.all([this.getCompanyById(companyId), getUsersByCompanyId(companyId, creditAssociation.isOnlineCounterparty)]).then(val => {
          this.invoiceForm.buyerCountry = val[0].incorporationCountry
          this.invoiceForm.buyerBuildingName = val[0].buildingName
          this.invoiceForm.buyerAddressLine1 = val[0].streetName
          this.invoiceForm.buyerAddressLine2 = val[0].unitNumber
          this.invoiceForm.buyerPostalCode = val[0].postal
          this.customerList = val[1]
          if (buyerSellerId) {
            this.isSellerView ? this.invoiceForm.buyerId = buyerSellerId : this.invoiceForm.sellerId = buyerSellerId
            this.handleBuyerNameSelect(buyerSellerId)
          }
        })
      } catch (e) {}
    },
    handleBuyerNameSelect (buyerSellerId) {
      const buyer = this.customerList.find(d => d.id === buyerSellerId)
      this.invoiceForm.buyerEmail = buyer.email
    },
    getCompanyById (companyId) {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.sharedCompanyBaseUrl}/${companyId}`
        }).then(res => {
          if (res?.code === 1000 && res.data) {
            resolve(res.data)
          }
        })
      })
    },
    submitInvoice (isCreateInvoice) {
      this.$refs.invoiceForm.validate((valid) => {
        if (valid) {
          if (this.invoiceForm.invoiceItems.length > 0) {
            if ((this.invoiceForm.invoiceFileId && !this.isSellerView) || this.isSellerView) {
              this.invoiceForm.isCommissionInvoice = this.isCommissionInvoice
              if (this.isSellerView) {
                this.invoiceForm.sellerSysOrganizationId = this.myCompanyId
                if (!this.isCreatedWithOrder) {
                  this.handleVerifyInvoiceCA().then(val => {
                    if (val) {
                      this.invoiceForm.creditAuthorizationRequestRemark = val
                    }
                    isCreateInvoice ? this.handleCreateInvoice() : this.handleUpdateInvoice()
                  })
                } else {
                  isCreateInvoice ? this.handleCreateInvoice() : this.handleUpdateInvoice()
                }
              } else {
                this.invoiceForm.buyerSysOrganizationId = this.myCompanyId
                isCreateInvoice ? this.handleCreateInvoice() : this.handleUpdateInvoice()
              }
            } else {
              this.$message.error('Invoice not uploaded.')
            }
          } else {
            this.$message.error('One or more invoice items must be added.')
          }
        } else {
          this.$message.error('Error. Required fields not filled in.')
        }
      })
    },
    handleVerifyInvoiceCA () {
      return new Promise((resolve, reject) => {
        verifyInvoiceCA(this.invoiceForm).then(res => {
          if (res?.code === 1000) {
            if (res.data) {
              const title = 'Confirm Submission?'
              const body = 'Credit Authorisation is required. Please enter a reason.'
              const validateCAField = (input) => {
                if (input.length < 1) {
                  return 'This field is required.'
                } else {
                  if (input.length > 500) {
                    return 'Character limit of 500 is exceeded.'
                  }
                  return true
                }
              }
              this.$prompt(body, title, {
                confirmButtonText: this.$t('submit'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning',
                inputType: 'textarea',
                inputValidator: validateCAField
              }).then(({ value }) => {
                resolve(value)
              }).catch(() => {})
            } else {
              resolve(false)
            }
          }
        }).catch(e => {
          reject(e)
        })
      })
    },
    handleCreateInvoice () {
      createInvoice(this.invoiceForm).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Invoice has been created.')
          this.handleCloseInvoiceForm('CREATE')
        }
      }).catch(e => {})
    },
    handleUpdateInvoice () {
      updateInvoice(this.invoiceForm).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Invoice has been updated.')
          this.handleCloseInvoiceForm('UPDATE')
        }
      }).catch(e => {})
    },
    populateInvoiceItemDocuments () {
      this.invoiceItemDocLoading = true
      const productIds = []
      this.invoiceForm.invoiceItems.forEach(i => {
        if (i.itemType === 'PRODUCT') {
          productIds.push(i.orderProductId)
        }
      })
      if (productIds) {
        this.getJobInfoByProductIds(productIds).then(val => {
          const jobProductMap = new Map(Object.entries(val))
          this.invoiceForm.invoiceItems.forEach((i, index) => {
            if (i.itemType === 'PRODUCT') {
              const jobs = jobProductMap.get(i.orderProductId)
              if (jobs) {
                this.$set(this.invoiceForm.invoiceItems[index], 'jobs', jobs)
              }
            }
          })
          this.invoiceItemDocLoading = false
        })
      }
    },
    getJobInfoByProductIds (productIds) {
      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.getJobInfoByProductIds,
          data: productIds
        }).then(res => {
          resolve(res.data)
        })
      })
    },
    addInvoiceItem () {
      const invoiceFormInvoiceItem = {
        itemType: null,
        description: null,
        quantity: null,
        price: null,
        unit: 'MT',
        supplyDate: null,
        amount: null,
        orderProductId: null,
        orderOtherCostId: null,
        receiptNo: null,
        gstType: null,
        gstPercentage: null
      }
      const invoiceItem = this.$_.cloneDeep(invoiceFormInvoiceItem)
      this.invoiceForm.invoiceItems.push(invoiceItem)
    },
    removeInvoiceItem (invoiceItem, index) {
      this.invoiceForm.invoiceItems.splice(index, 1)
    },
    calculateInvoiceValues () {
      let subTotal = 0
      let taxTotal = 0
      this.invoiceForm.invoiceItems.forEach((invoiceItem, index) => {
        if (!this.isCommissionInvoice) {
          this.invoiceForm.invoiceItems[index].amount = invoiceItem.quantity * invoiceItem.price
        }
        const tax = this.invoiceForm.invoiceItems[index].gstPercentage > 0 ? (this.invoiceForm.invoiceItems[index].amount * (this.invoiceForm.invoiceItems[index].gstPercentage / 100)) : 0
        taxTotal += tax
        subTotal += this.invoiceForm.invoiceItems[index].amount + tax
      })
      this.invoicePaymentInfo.subTotal = subTotal
      this.invoicePaymentInfo.taxTotal = taxTotal
      this.invoicePaymentInfo.invoiceTotal = this.invoicePaymentInfo.subTotal + this.invoicePaymentInfo.taxTotal
      this.invoicePaymentInfo.totalAmountBeforeGst = this.invoicePaymentInfo.invoiceTotal * this.invoiceForm.exchangeCurrencyRate
      this.invoicePaymentInfo.gstAmount = this.invoicePaymentInfo.taxTotal * this.invoiceForm.exchangeCurrencyRate
      this.invoicePaymentInfo.totalAmountAfterGst = this.invoicePaymentInfo.totalAmountBeforeGst + this.invoicePaymentInfo.gstAmount
    },
    invoiceDueDatePickerOptions () {
      const disabledDate = date => {
        return new Date(this.invoiceForm.invoiceDate) >= date
      }
      return { disabledDate }
    },
    handleInvoiceDateChange (date) {
      if (date >= new Date(this.invoiceForm.invoiceDueDate) || !date) {
        this.invoiceForm.invoiceDueDate = null
      }
    },
    async getSysProductAndSpecification () {
      const productRes = await this.$request.get({ url: `${this.$apis.products}` })
      const products = productRes?.code === 1000 ? productRes.data : []

      const specificationRes = await this.$request.get({ url: `${this.$apis.productSpecifications}` })
      const specifications = specificationRes?.code === 1000 ? specificationRes.data : []

      const options = products.map(p => {
        return { ...p, specifications }
      })
      this.productAndSpecificationOptions = options
    },
    handleInvoiceFormDropdownClick (val) {
      const title = `${capitalizeFirstLetter(val)} Invoice`
      const body = `Are you sure you want to ${val.toLowerCase()} this invoice?`
      this.$confirm(body, title, {
        confirmButtonText: `${capitalizeFirstLetter(val)}`,
        cancelButtonText: 'Close',
        type: 'warning'
      }).then(() => {
        if (val === 'DELETE') {
          this.handleDeleteInvoice()
        } else if (val === 'CANCEL') {
          this.handleCancelInvoice()
        }
      })
    },
    handleDeleteInvoice () {
      this.isInvoiceFormLoading = true
      deleteInvoice(this.invoiceForm.id)
        .then(res => {
          if (res?.code === 1000) {
            this.$message.success('Invoice has been deleted')
            this.handleCloseInvoiceForm('DELETE')
          } else {
            this.isInvoiceFormLoading = false
          }
        }).catch(e => { this.isInvoiceFormLoading = false })
    },
    handleCancelInvoice () {
      this.isInvoiceFormLoading = true
      cancelInvoice(this.invoiceForm.id).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Invoice has been cancelled')
          this.handleCloseInvoiceForm('CANCEL')
        } else {
          this.isInvoiceFormLoading = false
        }
      }).catch(e => { this.isInvoiceFormLoading = false })
    },
    applyCdNote () {
      this.isInvoiceFormLoading = true
      const noteIdsToBeAdded = []
      const creditNotes = this.$_.cloneDeep(this.invoiceForm.creditNotes)
      const debitNotes = this.$_.cloneDeep(this.invoiceForm.debitNotes)
      const appliedNotes = this.$_.cloneDeep(this.invoiceForm.appliedNotes)
      creditNotes.forEach(cn => {
        const i = this.invoiceForm.appliedNotes.findIndex(an => an.id === cn.id)
        i === -1 ? noteIdsToBeAdded.push(cn.id) : appliedNotes.splice(i, 1)
      })
      debitNotes.forEach(dn => {
        this.invoiceForm.appliedNotes.map(an => {
        })
        const i = this.invoiceForm.appliedNotes.findIndex(an => an.id === dn.id)
        i === -1 ? noteIdsToBeAdded.push(dn.id) : appliedNotes.splice(i, 1)
      })
      const data = {
        noteIdsToBeAdded: noteIdsToBeAdded,
        noteIdsToBeRemoved: appliedNotes.flatMap(an => an.id)
      }
      addRemoveInvoice(this.invoiceForm.id, data).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Invoice Credit/Debit Note has been updated')
          this.handleCloseInvoiceForm('CDNOTE')
        } else {
          this.isInvoiceFormLoading = false
        }
      }).catch(e => { this.isInvoiceFormLoading = false })
    },
    handleItemDescriptionCascaderChange (value, index) {
      const product = this.productAndSpecificationOptions.find(p => p.id === value[0])
      const specification = product.specifications.find(s => s.id === value[1])

      this.invoiceForm.invoiceItems[index].sysProductId = product.id
      this.invoiceForm.invoiceItems[index].sysProductSpecificationId = specification.id
      this.invoiceForm.invoiceItems[index].description = `${product.name} (${specification.name})`
    },
    // formInvoiceStatusStyle (status) {
    //   switch (status) {
    //     case INVOICE_STATUS.CREATED:
    //       return 'backgroundColor: #e3ffe2; color: #6fcf6d;'
    //     case INVOICE_STATUS.AUTHORIZED_SIGNATORY_APPROVED:
    //       return 'backgroundColor: #e3ffe2; color: #6fcf6d;'
    //     case 'SENT':
    //       return 'backgroundColor: #d4e6ff; color: #6995d5;'
    //     case INVOICE_STATUS.PENDING_CREDIT_AUTHORIZATION:
    //       return 'backgroundColor: #ffe6cc; color: #e7ad6f;'
    //     case INVOICE_STATUS.PENDING_AUTHORIZED_SIGNATORY_APPROVAL:
    //       return 'backgroundColor: #ffe6cc; color: #e7ad6f;'
    //     case INVOICE_STATUS.PENDING_TRADER_APPROVAL:
    //       return 'backgroundColor: #ffe6cc; color: #e7ad6f;'
    //     case INVOICE_STATUS.CREDIT_AUTHORIZATION_REJECTED:
    //       return 'backgroundColor: #f5dcdc; color: #d26868;'
    //     case INVOICE_STATUS.AUTHORIZED_SIGNATORY_REJECTED:
    //       return 'backgroundColor: #f5dcdc; color: #d26868;'
    //     case INVOICE_STATUS.REJECTED:
    //       return 'backgroundColor: #f5dcdc; color: #d26868;'
    //     case INVOICE_STATUS.OUTSTANDING:
    //       return 'backgroundColor: #ffeaea; color: #f4aaaa;'
    //     case INVOICE_STATUS.PAID:
    //       return 'backgroundColor: #cfd9e3; color: #89a1b0;'
    //     default: // CANCELLED
    //       return 'backgroundColor: #f5dcdc; color: #d26868;'
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index.scss";
  .div-frac-15-spec {
    grid-template-columns: 25% 25% 20% 20% 10%;
  }
</style>
