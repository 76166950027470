var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isInvoiceFormLoading,
          expression: "isInvoiceFormLoading",
        },
      ],
      staticClass: "div-form-main invoice-form",
    },
    [
      !_vm.isInvoiceFormLoading && !_vm.isViewOnly
        ? _c(
            "div",
            { staticClass: "div-form-main-btn" },
            [
              _vm.propsView === _vm.INVOICE_VIEW.CREATE_INVOICE
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.submitInvoice(true)
                        },
                      },
                    },
                    [_vm._v("Submit")]
                  )
                : _vm._e(),
              _vm.propsView === _vm.INVOICE_VIEW.EDIT_INVOICE
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.submitInvoice(false)
                        },
                      },
                    },
                    [_vm._v("Submit")]
                  )
                : _vm._e(),
              _vm.propsView === _vm.INVOICE_VIEW.VIEW_INVOICE &&
              _vm.hasPermission(_vm.$permissionFunctionCode.EDIT) &&
              ((_vm.isSellerView &&
                ((!_vm.isCreatedWithOrder &&
                  [
                    _vm.INVOICE_STATUS.AUTHORIZED_SIGNATORY_REJECTED,
                    _vm.INVOICE_STATUS.CREDIT_AUTHORIZATION_REJECTED,
                  ].includes(_vm.invoiceForm.sellerInvoiceStatus)) ||
                  (_vm.isCreatedWithOrder &&
                    _vm.invoiceForm.invoiceType ===
                      _vm.INVOICE_TYPE.COMMERCIAL &&
                    _vm.invoiceForm.sellerInvoiceStatus ===
                      _vm.INVOICE_STATUS.CREATED) ||
                  (_vm.isCreatedWithOrder &&
                    _vm.invoiceForm.invoiceType === _vm.INVOICE_TYPE.PROFORMA &&
                    [
                      _vm.INVOICE_STATUS.AUTHORIZED_SIGNATORY_REJECTED,
                      _vm.INVOICE_STATUS.PENDING_AUTHORIZED_SIGNATORY_APPROVAL,
                    ].includes(_vm.invoiceForm.sellerInvoiceStatus)))) ||
                (!_vm.isSellerView &&
                  _vm.invoiceForm.buyerInvoiceStatus ===
                    _vm.INVOICE_STATUS.PENDING_TRADER_APPROVAL &&
                  _vm.isCreatedWithOrder))
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary", size: "small" },
                      on: { click: _vm.handleInvoiceFormEditButtonClick },
                    },
                    [_vm._v(" Edit ")]
                  )
                : _vm._e(),
              _vm.isApplyCdNote
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary", size: "small" },
                      on: { click: _vm.applyCdNote },
                    },
                    [_vm._v(" Submit ")]
                  )
                : _vm._e(),
              !_vm.isApplyCdNote &&
              (_vm.propsView === _vm.INVOICE_VIEW.EDIT_INVOICE ||
                _vm.propsView === _vm.INVOICE_VIEW.VIEW_INVOICE) &&
              _vm.hasPermission(_vm.$permissionFunctionCode.EDIT) &&
              _vm.invoiceForm.invoiceType !== _vm.INVOICE_TYPE.PROFORMA &&
              ((_vm.isSellerView &&
                [
                  _vm.INVOICE_STATUS.SENT,
                  _vm.INVOICE_STATUS.PAID,
                  _vm.INVOICE_STATUS.OUTSTANDING,
                ].includes(_vm.invoiceForm.sellerInvoiceStatus)) ||
                (!_vm.isSellerView &&
                  _vm.invoiceForm.createdBySysOrganization ===
                    _vm.myCompanyId &&
                  [
                    _vm.INVOICE_STATUS.AUTHORIZED_SIGNATORY_APPROVED,
                    _vm.INVOICE_STATUS.PAID,
                    _vm.INVOICE_STATUS.OUTSTANDING,
                  ].includes(_vm.invoiceForm.sellerInvoiceStatus)))
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary", size: "small" },
                      on: { click: _vm.handleApplyCdNoteClick },
                    },
                    [_vm._v(" Apply Credit/Debit Note ")]
                  )
                : _vm._e(),
              !_vm.isViewOnly
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", outline: "", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCloseInvoiceForm("CLOSE")
                        },
                      },
                    },
                    [_vm._v("Close")]
                  )
                : _vm._e(),
              (_vm.propsView === _vm.INVOICE_VIEW.EDIT_INVOICE ||
                _vm.propsView === _vm.INVOICE_VIEW.VIEW_INVOICE) &&
              _vm.hasPermission(_vm.$permissionFunctionCode.EDIT) &&
              ((_vm.isSellerView &&
                [
                  _vm.INVOICE_STATUS.SENT,
                  _vm.INVOICE_STATUS.CREATED,
                  _vm.INVOICE_STATUS.CREDIT_AUTHORIZATION_REJECTED,
                  _vm.INVOICE_STATUS.AUTHORIZED_SIGNATORY_REJECTED,
                ].includes(_vm.invoiceForm.sellerInvoiceStatus)) ||
                (!_vm.isSellerView &&
                  [_vm.INVOICE_STATUS.PENDING_TRADER_APPROVAL].includes(
                    _vm.invoiceForm.buyerInvoiceStatus
                  ) &&
                  _vm.invoiceForm.createdBySysOrganization === _vm.myCompanyId))
                ? _c(
                    "el-dropdown",
                    {
                      staticClass: "el-dropdown-form-more-btn",
                      attrs: { trigger: "click" },
                      on: { command: _vm.handleInvoiceFormDropdownClick },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "el-dropdown-more-btn",
                          attrs: { size: "small", round: "" },
                        },
                        [_c("em", { staticClass: "el-icon-more" })]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _vm.isSellerView &&
                          _vm.invoiceForm.sellerInvoiceStatus ===
                            _vm.INVOICE_STATUS.SENT
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "CANCEL" } },
                                [_vm._v(" Cancel Invoice ")]
                              )
                            : _vm._e(),
                          (_vm.isSellerView &&
                            [
                              _vm.INVOICE_STATUS.CREATED,
                              _vm.INVOICE_STATUS.CREDIT_AUTHORIZATION_REJECTED,
                              _vm.INVOICE_STATUS.AUTHORIZED_SIGNATORY_REJECTED,
                            ].includes(_vm.invoiceForm.sellerInvoiceStatus)) ||
                          (!_vm.isSellerView &&
                            [
                              _vm.INVOICE_STATUS.PENDING_TRADER_APPROVAL,
                            ].includes(_vm.invoiceForm.buyerInvoiceStatus) &&
                            _vm.invoiceForm.createdBySysOrganization ===
                              _vm.myCompanyId)
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "DELETE" } },
                                [_vm._v(" Delete Invoice ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.isInvoiceFormLoading &&
      [
        _vm.INVOICE_VIEW.CREATE_INVOICE,
        _vm.INVOICE_VIEW.VIEW_INVOICE,
        _vm.INVOICE_VIEW.EDIT_INVOICE,
      ].includes(_vm.propsView)
        ? _c(
            "div",
            { staticClass: "div-form-main-container" },
            [
              _c(
                "el-form",
                {
                  ref: "invoiceForm",
                  attrs: {
                    model: _vm.invoiceForm,
                    "validate-on-rule-change": false,
                  },
                },
                [
                  _c("div", { staticClass: "basic-card-block margin-top-0" }, [
                    _c("h1", [_vm._v("Invoice Information")]),
                    _c("div", [
                      !_vm.isSellerView &&
                      _vm.propsView === _vm.INVOICE_VIEW.CREATE_INVOICE
                        ? _c(
                            "div",
                            { staticClass: "div-upload-commission-checkbox" },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action: _vm.fileUploadUrl,
                                        accept: ".pdf",
                                        limit: 1,
                                        "file-list": _vm.invoiceForm.fileList,
                                        "on-success":
                                          _vm.handleInvoiceUploadSuccess(),
                                        "before-remove":
                                          _vm.handleInvoiceBeforeRemove,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            plain: "",
                                            round: "",
                                            type: "primary",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "span-red-asterik" },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(" Upload Invoice "),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.isSellerView &&
                              _vm.invoiceForm.orderType ===
                                _vm.ORDER_TYPE.DELIVERED
                                ? _c(
                                    "el-checkbox",
                                    {
                                      staticClass: "checkbox-commission",
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleInvoiceTypeSelect(
                                            $event,
                                            true
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.isCommissionInvoice,
                                        callback: function ($$v) {
                                          _vm.isCommissionInvoice = $$v
                                        },
                                        expression: "isCommissionInvoice",
                                      },
                                    },
                                    [_vm._v(" Commission Invoice ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "div-frac-13" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Invoice Type",
                                prop: "invoiceType",
                                rules: _vm.rules.reqRule,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled:
                                      (_vm.invoiceForm.invoiceType ===
                                        _vm.INVOICE_TYPE.COMMERCIAL &&
                                        Object.keys(_vm.orderSelected).length >
                                          0 &&
                                        _vm.isSellerView) ||
                                      _vm.propsView ===
                                        _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                      _vm.propsView ===
                                        _vm.INVOICE_VIEW.EDIT_INVOICE ||
                                      _vm.isCommissionInvoice,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleInvoiceTypeSelect(
                                        $event,
                                        false
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.invoiceForm.invoiceType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoiceForm,
                                        "invoiceType",
                                        $$v
                                      )
                                    },
                                    expression: "invoiceForm.invoiceType",
                                  },
                                },
                                _vm._l(
                                  _vm.INVOICE_TYPE_OPTIONS,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Invoice Date",
                                prop: "invoiceDate",
                                rules: _vm.rules.reqRule,
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "dd-MM-yyyy",
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                on: { change: _vm.handleInvoiceDateChange },
                                model: {
                                  value: _vm.invoiceForm.invoiceDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "invoiceDate",
                                      $$v
                                    )
                                  },
                                  expression: "invoiceForm.invoiceDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Invoice Due Date",
                                prop: "invoiceDueDate",
                                rules: _vm.invoiceForm.invoiceDate
                                  ? _vm.rules.reqRule
                                  : _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  disabled:
                                    !_vm.invoiceForm.invoiceDate ||
                                    _vm.propsView ===
                                      _vm.INVOICE_VIEW.VIEW_INVOICE,
                                  type: "date",
                                  format: "dd-MM-yyyy",
                                  "picker-options":
                                    _vm.invoiceDueDatePickerOptions(),
                                },
                                model: {
                                  value: _vm.invoiceForm.invoiceDueDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "invoiceDueDate",
                                      $$v
                                    )
                                  },
                                  expression: "invoiceForm.invoiceDueDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "basic-card-block" }, [
                    _c("h1", [_vm._v("Order Information")]),
                    _c(
                      "div",
                      { staticClass: "div-order-info-body" },
                      [
                        (_vm.invoiceForm.invoiceType ===
                          _vm.INVOICE_TYPE.PROFORMA &&
                          _vm.propsView === _vm.INVOICE_VIEW.CREATE_INVOICE) ||
                        (!_vm.isSellerView &&
                          _vm.invoiceForm.invoiceType ===
                            _vm.INVOICE_TYPE.COMMERCIAL &&
                          _vm.propsView === _vm.INVOICE_VIEW.CREATE_INVOICE)
                          ? _c(
                              "el-button",
                              {
                                attrs: { round: "", outline: "", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogSelectOrder = true
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "span-red-asterik" },
                                  [_vm._v("*")]
                                ),
                                _vm._v(" Select Order "),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "div-frac-13" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Order Number",
                                  prop: "orderNo",
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled:
                                      !(
                                        _vm.invoiceForm.invoiceType ===
                                          _vm.INVOICE_TYPE.COMMERCIAL &&
                                        Object.keys(_vm.orderSelected)
                                          .length === 0
                                      ) ||
                                      _vm.propsView ===
                                        _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                      (!_vm.isSellerView &&
                                        _vm.invoiceForm.invoiceType ===
                                          _vm.INVOICE_TYPE.COMMERCIAL),
                                  },
                                  model: {
                                    value: _vm.invoiceForm.orderNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.invoiceForm, "orderNo", $$v)
                                    },
                                    expression: "invoiceForm.orderNo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.isSellerView
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Seller Name",
                                      prop: "sellerId",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          disabled:
                                            _vm.propsView ===
                                              _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                            (_vm.invoiceForm.invoiceType ===
                                              _vm.INVOICE_TYPE.PROFORMA &&
                                              Object.keys(_vm.orderSelected)
                                                .length === 0) ||
                                            (!_vm.isSellerView &&
                                              _vm.invoiceForm.invoiceType ===
                                                _vm.INVOICE_TYPE.COMMERCIAL &&
                                              !_vm.isCommissionInvoice),
                                        },
                                        model: {
                                          value: _vm.invoiceForm.sellerId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invoiceForm,
                                              "sellerId",
                                              $$v
                                            )
                                          },
                                          expression: "invoiceForm.sellerId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.currentCompanyUserList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Buyer Name",
                                      prop: "buyerId",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          disabled:
                                            _vm.propsView ===
                                              _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                            ((_vm.invoiceForm.invoiceType ===
                                              _vm.INVOICE_TYPE.PROFORMA ||
                                              _vm.invoiceForm.invoiceType ===
                                                _vm.INVOICE_TYPE.COMMERCIAL) &&
                                              Object.keys(_vm.orderSelected)
                                                .length === 0),
                                        },
                                        model: {
                                          value: _vm.invoiceForm.buyerId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invoiceForm,
                                              "buyerId",
                                              $$v
                                            )
                                          },
                                          expression: "invoiceForm.buyerId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.currentCompanyUserList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Port",
                                  prop: "orderPort",
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled:
                                      (_vm.invoiceForm.invoiceType ===
                                        _vm.INVOICE_TYPE.PROFORMA &&
                                        Object.keys(_vm.orderSelected)
                                          .length === 0) ||
                                      _vm.propsView ===
                                        _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                      (!_vm.isSellerView &&
                                        _vm.invoiceForm.invoiceType ===
                                          _vm.INVOICE_TYPE.COMMERCIAL &&
                                        Object.keys(_vm.orderSelected)
                                          .length === 0),
                                  },
                                  model: {
                                    value: _vm.invoiceForm.orderPort,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoiceForm,
                                        "orderPort",
                                        $$v
                                      )
                                    },
                                    expression: "invoiceForm.orderPort",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.invoiceForm.orderType ===
                              _vm.ORDER_TYPE.DELIVERED ||
                            _vm.invoiceForm.orderType ===
                              _vm.ORDER_TYPE.BARGING_NOMINATION
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Vessel",
                                      prop: "orderVesselName",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          disabled:
                                            (_vm.invoiceForm.invoiceType ===
                                              _vm.INVOICE_TYPE.PROFORMA &&
                                              Object.keys(_vm.orderSelected)
                                                .length === 0) ||
                                            _vm.propsView ===
                                              _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                            (!_vm.isSellerView &&
                                              _vm.invoiceForm.invoiceType ===
                                                _vm.INVOICE_TYPE.COMMERCIAL &&
                                              Object.keys(_vm.orderSelected)
                                                .length === 0),
                                        },
                                        on: {
                                          change: _vm.handleVesselNameSelect,
                                        },
                                        model: {
                                          value:
                                            _vm.invoiceForm.orderVesselName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invoiceForm,
                                              "orderVesselName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "invoiceForm.orderVesselName",
                                        },
                                      },
                                      _vm._l(_vm.vesselList, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.shipName,
                                            value: item.shipName,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.invoiceForm.orderType ===
                              _vm.ORDER_TYPE.DELIVERED ||
                            _vm.invoiceForm.orderType ===
                              _vm.ORDER_TYPE.BARGING_NOMINATION
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Imo No.",
                                      prop: "orderVesselImo",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          disabled:
                                            (_vm.invoiceForm.invoiceType ===
                                              _vm.INVOICE_TYPE.PROFORMA &&
                                              Object.keys(_vm.orderSelected)
                                                .length === 0) ||
                                            _vm.propsView ===
                                              _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                            (!_vm.isSellerView &&
                                              _vm.invoiceForm.invoiceType ===
                                                _vm.INVOICE_TYPE.COMMERCIAL &&
                                              Object.keys(_vm.orderSelected)
                                                .length === 0),
                                        },
                                        on: {
                                          change: _vm.handleVesselImoSelect,
                                        },
                                        model: {
                                          value: _vm.invoiceForm.orderVesselImo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invoiceForm,
                                              "orderVesselImo",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "invoiceForm.orderVesselImo",
                                        },
                                      },
                                      _vm._l(_vm.vesselList, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.imo,
                                            value: item.imo,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Terminal",
                                      prop: "orderTerminal",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          disabled:
                                            (_vm.invoiceForm.invoiceType ===
                                              _vm.INVOICE_TYPE.PROFORMA &&
                                              Object.keys(_vm.orderSelected)
                                                .length === 0) ||
                                            _vm.propsView ===
                                              _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                            (!_vm.isSellerView &&
                                              _vm.invoiceForm.invoiceType ===
                                                _vm.INVOICE_TYPE.COMMERCIAL &&
                                              Object.keys(_vm.orderSelected)
                                                .length === 0),
                                        },
                                        model: {
                                          value: _vm.invoiceForm.orderTerminal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invoiceForm,
                                              "orderTerminal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "invoiceForm.orderTerminal",
                                        },
                                      },
                                      _vm._l(_vm.locationList, function (item) {
                                        return _c("el-option", {
                                          key: item.name,
                                          attrs: {
                                            label: item.name,
                                            value: item.name,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Location",
                                  prop: "orderLocation",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled:
                                      (_vm.invoiceForm.invoiceType ===
                                        _vm.INVOICE_TYPE.PROFORMA &&
                                        Object.keys(_vm.orderSelected)
                                          .length === 0) ||
                                      _vm.propsView ===
                                        _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                      (!_vm.isSellerView &&
                                        _vm.invoiceForm.invoiceType ===
                                          _vm.INVOICE_TYPE.COMMERCIAL &&
                                        Object.keys(_vm.orderSelected)
                                          .length === 0),
                                  },
                                  model: {
                                    value: _vm.invoiceForm.orderLocation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoiceForm,
                                        "orderLocation",
                                        $$v
                                      )
                                    },
                                    expression: "invoiceForm.orderLocation",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Currency",
                                  prop: "orderCurrency",
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { disabled: "" },
                                    on: { change: _vm.calculateInvoiceValues },
                                    model: {
                                      value: _vm.invoiceForm.orderCurrency,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceForm,
                                          "orderCurrency",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceForm.orderCurrency",
                                    },
                                  },
                                  _vm._l(_vm.currencyList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.code,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Payment Terms",
                                  prop: "orderPaymentTerm",
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled:
                                        (_vm.invoiceForm.invoiceType ===
                                          _vm.INVOICE_TYPE.PROFORMA &&
                                          Object.keys(_vm.orderSelected)
                                            .length === 0) ||
                                        _vm.propsView ===
                                          _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                        (!_vm.isSellerView &&
                                          _vm.invoiceForm.invoiceType ===
                                            _vm.INVOICE_TYPE.COMMERCIAL &&
                                          Object.keys(_vm.orderSelected)
                                            .length === 0),
                                    },
                                    on: { change: _vm.handlePaymentTermChange },
                                    model: {
                                      value: _vm.invoiceForm.orderPaymentTerm,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceForm,
                                          "orderPaymentTerm",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "invoiceForm.orderPaymentTerm",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PAYMENT_TERM_OPTIONS,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.invoiceForm.orderPaymentTerm !== "SPECIFIC_DATE"
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Credit Days",
                                      prop: "orderCreditDay",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        disabled:
                                          (_vm.invoiceForm.invoiceType ===
                                            _vm.INVOICE_TYPE.PROFORMA &&
                                            Object.keys(_vm.orderSelected)
                                              .length === 0) ||
                                          _vm.propsView ===
                                            _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                          (!_vm.isSellerView &&
                                            _vm.invoiceForm.invoiceType ===
                                              _vm.INVOICE_TYPE.COMMERCIAL &&
                                            Object.keys(_vm.orderSelected)
                                              .length === 0) ||
                                          _vm.invoiceForm.orderPaymentTerm ===
                                            "CASH_IN_ADVANCE",
                                        controls: false,
                                        precision: 0,
                                        min: 0,
                                      },
                                      model: {
                                        value: _vm.invoiceForm.orderCreditDay,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.invoiceForm,
                                            "orderCreditDay",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "invoiceForm.orderCreditDay",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Payment Term Date",
                                      prop: "orderPaymentTermDate",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        disabled:
                                          (_vm.invoiceForm.invoiceType ===
                                            _vm.INVOICE_TYPE.PROFORMA &&
                                            Object.keys(_vm.orderSelected)
                                              .length === 0) ||
                                          _vm.propsView ===
                                            _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                          (!_vm.isSellerView &&
                                            _vm.invoiceForm.invoiceType ===
                                              _vm.INVOICE_TYPE.COMMERCIAL &&
                                            Object.keys(_vm.orderSelected)
                                              .length === 0),
                                        type: "date",
                                        format: "dd-MM-yyyy",
                                      },
                                      model: {
                                        value:
                                          _vm.invoiceForm.orderPaymentTermDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.invoiceForm,
                                            "orderPaymentTermDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "invoiceForm.orderPaymentTermDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.isSellerView
                    ? _c("div", { staticClass: "basic-card-block" }, [
                        _c("h1", [_vm._v("Customer Information")]),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "div-frac-14" },
                            [
                              _vm.creditAssociationList
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Company Name",
                                        prop: "buyerSysOrganizationId",
                                        rules: _vm.rules.reqRule,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            "value-key": "id",
                                            disabled:
                                              _vm.propsView ===
                                                _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                              _vm.propsView ===
                                                _vm.INVOICE_VIEW.EDIT_INVOICE,
                                          },
                                          on: {
                                            change: _vm.handleCompanyNameSelect,
                                          },
                                          model: {
                                            value:
                                              _vm.invoiceForm
                                                .buyerSysOrganizationId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.invoiceForm,
                                                "buyerSysOrganizationId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "invoiceForm.buyerSysOrganizationId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.creditAssociationList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label:
                                                  item.counterpartyCompanyName,
                                                value:
                                                  item.counterpartySysOrganizationId,
                                                disabled:
                                                  item.status !== "ACTIVE",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Customer Name",
                                    prop: "buyerId",
                                    rules: _vm.rules.reqRule,
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        "value-key": "id",
                                        disabled:
                                          _vm.propsView ===
                                          _vm.INVOICE_VIEW.VIEW_INVOICE,
                                      },
                                      on: { change: _vm.handleBuyerNameSelect },
                                      model: {
                                        value: _vm.invoiceForm.buyerId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.invoiceForm,
                                            "buyerId",
                                            $$v
                                          )
                                        },
                                        expression: "invoiceForm.buyerId",
                                      },
                                    },
                                    _vm._l(_vm.customerList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Email Address",
                                    prop: "buyerEmail",
                                    rules: _vm.rules.emaileRule,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.propsView ===
                                        _vm.INVOICE_VIEW.VIEW_INVOICE,
                                    },
                                    model: {
                                      value: _vm.invoiceForm.buyerEmail,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceForm,
                                          "buyerEmail",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceForm.buyerEmail",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Country",
                                    prop: "buyerCountry",
                                    rules: _vm.rules.reqRule,
                                  },
                                },
                                [
                                  _c("country-selector", {
                                    attrs: {
                                      disabled:
                                        _vm.propsView ===
                                        _vm.INVOICE_VIEW.VIEW_INVOICE,
                                    },
                                    model: {
                                      value: _vm.invoiceForm.buyerCountry,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceForm,
                                          "buyerCountry",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceForm.buyerCountry",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "div-frac-14" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Building Name",
                                    prop: "buyerBuildingName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.propsView ===
                                        _vm.INVOICE_VIEW.VIEW_INVOICE,
                                    },
                                    model: {
                                      value: _vm.invoiceForm.buyerBuildingName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceForm,
                                          "buyerBuildingName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "invoiceForm.buyerBuildingName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Address Line 1",
                                    prop: "buyerAddressLine1",
                                    rules: _vm.rules.reqRule,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.propsView ===
                                        _vm.INVOICE_VIEW.VIEW_INVOICE,
                                    },
                                    model: {
                                      value: _vm.invoiceForm.buyerAddressLine1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceForm,
                                          "buyerAddressLine1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "invoiceForm.buyerAddressLine1",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Address Line 2",
                                    prop: "buyerAddressLine2",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.propsView ===
                                        _vm.INVOICE_VIEW.VIEW_INVOICE,
                                    },
                                    model: {
                                      value: _vm.invoiceForm.buyerAddressLine2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceForm,
                                          "buyerAddressLine2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "invoiceForm.buyerAddressLine2",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Postal Code",
                                    prop: "buyerPostalCode",
                                    rules: _vm.rules.reqRule,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.propsView ===
                                        _vm.INVOICE_VIEW.VIEW_INVOICE,
                                    },
                                    model: {
                                      value: _vm.invoiceForm.buyerPostalCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceForm,
                                          "buyerPostalCode",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceForm.buyerPostalCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _c("div", { staticClass: "basic-card-block" }, [
                        _c("h1", [_vm._v("Seller Information")]),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "div-frac-12" },
                            [
                              _vm.creditAssociationList
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Company Name",
                                        prop: "sellerSysOrganizationId",
                                        rules: _vm.rules.reqRule,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            "value-key": "id",
                                            disabled:
                                              _vm.propsView ===
                                                _vm.INVOICE_VIEW.VIEW_INVOICE ||
                                              _vm.propsView ===
                                                _vm.INVOICE_VIEW.EDIT_INVOICE,
                                          },
                                          on: {
                                            change: _vm.handleCompanyNameSelect,
                                          },
                                          model: {
                                            value:
                                              _vm.invoiceForm
                                                .sellerSysOrganizationId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.invoiceForm,
                                                "sellerSysOrganizationId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "invoiceForm.sellerSysOrganizationId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.creditAssociationList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label:
                                                  item.counterpartyCompanyName,
                                                value:
                                                  item.counterpartySysOrganizationId,
                                                disabled:
                                                  item.status !== "ACTIVE",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Seller Name",
                                    prop: "sellerId",
                                    rules: _vm.rules.reqRule,
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        "value-key": "id",
                                        disabled:
                                          _vm.propsView ===
                                          _vm.INVOICE_VIEW.VIEW_INVOICE,
                                      },
                                      model: {
                                        value: _vm.invoiceForm.sellerId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.invoiceForm,
                                            "sellerId",
                                            $$v
                                          )
                                        },
                                        expression: "invoiceForm.sellerId",
                                      },
                                    },
                                    _vm._l(_vm.customerList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                  _c("div", { staticClass: "basic-card-block" }, [
                    _c("h1", [_vm._v("Item")]),
                    _c(
                      "div",
                      { staticClass: "div-item-info-body" },
                      [
                        !_vm.isCreatedWithOrder &&
                        _vm.isSellerView &&
                        !_vm.isViewOnly
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  round: "",
                                  size: "mini",
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                on: { click: _vm.addInvoiceItem },
                              },
                              [_vm._v(" Add ")]
                            )
                          : !_vm.isViewOnly
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled:
                                    Object.keys(_vm.orderSelected).length ===
                                      0 ||
                                    _vm.propsView ===
                                      _vm.INVOICE_VIEW.VIEW_INVOICE,
                                  outline: "",
                                  round: "",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogSelectInvoiceItems = true
                                  },
                                },
                              },
                              [_vm._v(" Add ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-table",
                          {
                            staticClass: "el-table-invoice-items-cdNotes",
                            attrs: {
                              data: _vm.invoiceForm.invoiceItems,
                              border: "",
                              loading: _vm.invoiceItemDocLoading,
                              "header-cell-style": { color: "#909399" },
                              "cell-style": {
                                alignSelf: "center",
                                padding: "0",
                                height: "80px",
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "itemType", "min-width": "150px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c("span", [_vm._v("* ")]),
                                        _vm._v("Item Type"),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: `invoiceItems.${scope.$index}.itemType`,
                                              rules: _vm.rules.reqRule,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.isCreatedWithOrder ||
                                                    _vm.propsView ===
                                                      _vm.INVOICE_VIEW
                                                        .VIEW_INVOICE ||
                                                    (!_vm.isSellerView &&
                                                      _vm.invoiceForm
                                                        .invoiceType ===
                                                        _vm.INVOICE_TYPE
                                                          .COMMERCIAL),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.handleInvoiceItemTypeSelect(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.itemType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "itemType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.itemType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.isCommissionInvoice
                                                  ? _vm.ORDER_ITEM_TYPE_OPTIONS
                                                  : _vm.ORDER_ITEM_TYPE_OPTIONS.filter(
                                                      (d) =>
                                                        d.value !== "COMMISSION"
                                                    ),
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1608159860
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "description",
                                "min-width": "320px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c("span", [_vm._v("* ")]),
                                        _vm._v("Description"),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: `invoiceItems.${scope.$index}.description`,
                                              rules: !scope.row.itemType
                                                ? _vm.rules.notReqRule
                                                : _vm.rules.reqRule,
                                            },
                                          },
                                          [
                                            scope.row.itemType !==
                                            _vm.ORDER_ITEM_TYPE.PRODUCT
                                              ? _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !scope.row.itemType ||
                                                        _vm.isCreatedWithOrder ||
                                                        _vm.propsView ===
                                                          _vm.INVOICE_VIEW
                                                            .VIEW_INVOICE ||
                                                        (!_vm.isSellerView &&
                                                          _vm.invoiceForm
                                                            .invoiceType ===
                                                            _vm.INVOICE_TYPE
                                                              .COMMERCIAL),
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.description,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.description",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.ORDER_OTHER_COST_DESCRIPTION_OPTIONS,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              : (scope.row.itemType ===
                                                  _vm.ORDER_ITEM_TYPE.PRODUCT &&
                                                  _vm.isCreatedWithOrder) ||
                                                (!_vm.isSellerView &&
                                                  _vm.invoiceForm
                                                    .invoiceType ===
                                                    _vm.INVOICE_TYPE.COMMERCIAL)
                                              ? _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      scope.row.description,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "description",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.description",
                                                  },
                                                })
                                              : scope.row.itemType ===
                                                  _vm.ORDER_ITEM_TYPE.PRODUCT &&
                                                !_vm.isCreatedWithOrder
                                              ? _c("el-cascader", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.propsView ===
                                                      _vm.INVOICE_VIEW
                                                        .VIEW_INVOICE,
                                                    options:
                                                      _vm.productAndSpecificationOptions,
                                                    props: {
                                                      key: "id",
                                                      value: "id",
                                                      label: "name",
                                                      children:
                                                        "specifications",
                                                      expandTrigger: "hover",
                                                    },
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.handleItemDescriptionCascaderChange(
                                                        $event,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.descriptionName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "descriptionName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.descriptionName",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2979978029
                              ),
                            }),
                            _vm.invoiceForm.invoiceType ===
                              _vm.INVOICE_TYPE.COMMERCIAL &&
                            !_vm.isCommissionInvoice
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "supplyDate",
                                    label: "Supply Date",
                                    "min-width": "150px",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function () {
                                          return [
                                            _c("span", [_vm._v("* ")]),
                                            _vm._v("Supply Date"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: `invoiceItems.${scope.$index}.supplyDate`,
                                                  rules:
                                                    _vm.invoiceForm
                                                      .invoiceType ===
                                                    _vm.INVOICE_TYPE.COMMERCIAL
                                                      ? _vm.rules.reqRule
                                                      : _vm.rules.notReqRule,
                                                },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    disabled:
                                                      (_vm.isCreatedWithOrder &&
                                                        _vm.isSellerView) ||
                                                      _vm.propsView ===
                                                        "VIEW_INVOICE",
                                                    type: "date",
                                                    format: "dd-MM-yyyy",
                                                  },
                                                  model: {
                                                    value: scope.row.supplyDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "supplyDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.supplyDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    454556009
                                  ),
                                })
                              : _vm._e(),
                            !_vm.isCommissionInvoice
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "",
                                    label: "Unit Metric",
                                    "min-width": "100px",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: `invoiceItems.${scope.$index}.unit`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value: scope.row.unit,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "unit",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.unit",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.UNIT_OPTIONS,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3335569051
                                  ),
                                })
                              : _vm._e(),
                            !_vm.isCommissionInvoice
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "",
                                    label: "Quantity",
                                    "min-width": "100px",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function () {
                                          return [
                                            _c("span", [_vm._v("* ")]),
                                            _vm._v("Quantity"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: `invoiceItems.${scope.$index}.quantity`,
                                                  rules:
                                                    _vm.rules
                                                      .reqGreaterThanZeroRule,
                                                },
                                              },
                                              [
                                                _c("el-input-number", {
                                                  attrs: {
                                                    disabled:
                                                      (_vm.isCreatedWithOrder &&
                                                        _vm.isSellerView) ||
                                                      _vm.propsView ===
                                                        _vm.INVOICE_VIEW
                                                          .VIEW_INVOICE,
                                                    controls: false,
                                                    precision: 3,
                                                    min: 0,
                                                  },
                                                  on: {
                                                    blur: _vm.calculateInvoiceValues,
                                                  },
                                                  model: {
                                                    value: scope.row.quantity,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "quantity",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.quantity",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    565013295
                                  ),
                                })
                              : _vm._e(),
                            !_vm.isCommissionInvoice
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "",
                                    label: "Price",
                                    "min-width": "100px",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function () {
                                          return [
                                            _c("span", [_vm._v("* ")]),
                                            _vm._v("Price"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: `invoiceItems.${scope.$index}.price`,
                                                  rules:
                                                    _vm.rules
                                                      .reqGreaterThanZeroRule,
                                                },
                                              },
                                              [
                                                _c("el-input-number", {
                                                  attrs: {
                                                    disabled:
                                                      (_vm.isCreatedWithOrder &&
                                                        _vm.isSellerView) ||
                                                      _vm.propsView ===
                                                        _vm.INVOICE_VIEW
                                                          .VIEW_INVOICE,
                                                    controls: false,
                                                    precision: 3,
                                                    min: 0,
                                                  },
                                                  on: {
                                                    blur: _vm.calculateInvoiceValues,
                                                  },
                                                  model: {
                                                    value: scope.row.price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "price",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.price",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    487696281
                                  ),
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: { prop: "", "min-width": "120px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c("span", [_vm._v("* ")]),
                                        _vm._v("Amount"),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: `invoiceItems.${scope.$index}.amount`,
                                              rules:
                                                _vm.rules
                                                  .reqGreaterThanZeroRule,
                                            },
                                          },
                                          [
                                            _c("el-input-number", {
                                              attrs: {
                                                disabled: true,
                                                controls: false,
                                                precision: 3,
                                                min: 0,
                                              },
                                              on: {
                                                blur: _vm.calculateInvoiceValues,
                                              },
                                              model: {
                                                value: scope.row.amount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "amount",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.amount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2106066207
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "gstType", "min-width": "200px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c("span", [_vm._v("* ")]),
                                        _vm._v("GST Type"),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: `invoiceItems.${scope.$index}.gstType`,
                                              rules: _vm.rules.reqRule,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.propsView ===
                                                    _vm.INVOICE_VIEW
                                                      .VIEW_INVOICE,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.handleGstTypeSelect(
                                                      scope.$index,
                                                      scope.row.gstType
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.gstType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "gstType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.gstType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.GST_TYPE_OPTIONS,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                301488775
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "gstPercentage",
                                "min-width": "100px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c("span", [_vm._v("* ")]),
                                        _vm._v("GST %"),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: `invoiceItems.${scope.$index}.gstPercentage`,
                                            },
                                          },
                                          [
                                            _c("el-input-number", {
                                              attrs: {
                                                disabled: "",
                                                controls: false,
                                                precision: 2,
                                                min: 0,
                                              },
                                              on: {
                                                blur: _vm.calculateInvoiceValues,
                                              },
                                              model: {
                                                value: scope.row.gstPercentage,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "gstPercentage",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.gstPercentage",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1523697074
                              ),
                            }),
                            !_vm.isCreatedWithOrder
                              ? _c("el-table-column", {
                                  attrs: {
                                    label: "Receipt No.",
                                    prop: "receiptNo",
                                    "min-width": "250px",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: `invoiceItems.${scope.$index}.receiptNo`,
                                                  rules:
                                                    scope.row.itemType ===
                                                    _vm.ORDER_ITEM_TYPE.PRODUCT
                                                      ? _vm.rules.reqRule
                                                      : _vm.rules.notReqRule,
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      scope.row.itemType !==
                                                        _vm.ORDER_ITEM_TYPE
                                                          .PRODUCT ||
                                                      _vm.propsView ===
                                                        _vm.INVOICE_VIEW
                                                          .VIEW_INVOICE ||
                                                      (_vm.isCreatedWithOrder &&
                                                        _vm.invoiceForm
                                                          .invoiceType ===
                                                          _vm.INVOICE_TYPE
                                                            .COMMERCIAL &&
                                                        _vm.isSellerView),
                                                  },
                                                  model: {
                                                    value: scope.row.receiptNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "receiptNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.receiptNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2200226150
                                  ),
                                })
                              : _vm._e(),
                            _vm.isCreatedWithOrder &&
                            _vm.invoiceForm.invoiceType ===
                              _vm.INVOICE_TYPE.COMMERCIAL &&
                            _vm.invoiceForm.orderType !==
                              _vm.ORDER_TYPE.EX_WHARF
                              ? _c("el-table-column", {
                                  staticClass: "el-table-column-item-bdn-doc",
                                  attrs: {
                                    label: "BDN Document",
                                    "min-width": "150px",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.jobs &&
                                            scope.row.jobs.length > 0
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    scope.row.jobs,
                                                    function (job) {
                                                      return _c(
                                                        "div",
                                                        { key: job.jobId },
                                                        [
                                                          scope.row.itemType ===
                                                          _vm.ORDER_ITEM_TYPE
                                                            .PRODUCT
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "primary",
                                                                    plain: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.previewFile(
                                                                          job.bdnPdfId
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      job.docNumber
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _c("p", [
                                                                _vm._v("-"),
                                                              ]),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                    },
                                                  },
                                                  [_vm._v("-")]
                                                ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1927219051
                                  ),
                                })
                              : _vm.isCreatedWithOrder &&
                                _vm.invoiceForm.invoiceType ===
                                  _vm.INVOICE_TYPE.COMMERCIAL &&
                                _vm.invoiceForm.orderType ===
                                  _vm.ORDER_TYPE.EX_WHARF
                              ? _c("el-table-column", {
                                  attrs: {
                                    label: "Loading Documents",
                                    width: "200",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.jobs &&
                                            scope.row.jobs.length > 0
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    scope.row.jobs,
                                                    function (job) {
                                                      return _c(
                                                        "div",
                                                        { key: job.jobId },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                plain: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFile(
                                                                      job.cqPdfId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  `[${job.docNumber}] CQ File`
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                margin: "0",
                                                              },
                                                              attrs: {
                                                                type: "primary",
                                                                plain: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFile(
                                                                      job.coqPdfId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  `[${job.docNumber}] COQ File`
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                    },
                                                  },
                                                  [_vm._v("-")]
                                                ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1112205563
                                  ),
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                label: "",
                                width: "50px",
                                fixed: "right",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-button", {
                                          attrs: {
                                            type: "danger",
                                            icon: "el-icon-delete",
                                            size: "mini",
                                            circle: "",
                                            disabled:
                                              _vm.invoiceForm.invoiceItems
                                                .length === 1 ||
                                              _vm.propsView ===
                                                _vm.INVOICE_VIEW.VIEW_INVOICE,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeInvoiceItem(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1103754817
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "basic-card-block" }, [
                    _c("h1", [_vm._v("Payment Information")]),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "div-frac-12" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Exchange Currency",
                                prop: "exchangeCurrency",
                                rules: _vm.rules.reqRule,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.propsView ===
                                      _vm.INVOICE_VIEW.VIEW_INVOICE,
                                  },
                                  model: {
                                    value: _vm.invoiceForm.exchangeCurrency,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoiceForm,
                                        "exchangeCurrency",
                                        $$v
                                      )
                                    },
                                    expression: "invoiceForm.exchangeCurrency",
                                  },
                                },
                                _vm._l(_vm.currencyList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.code,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Exchange Currency Rate",
                                prop: "exchangeCurrencyRate",
                                rules: _vm.rules.reqGreaterThanZeroRule,
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                  controls: false,
                                  precision: 3,
                                  min: 0,
                                },
                                on: { blur: _vm.calculateInvoiceValues },
                                model: {
                                  value: _vm.invoiceForm.exchangeCurrencyRate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "exchangeCurrencyRate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "invoiceForm.exchangeCurrencyRate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "basic-card-block" }, [
                    _c("h1", [_vm._v("Bank Information")]),
                    _c("div", [
                      _c("div", { staticClass: "div-bank-info-title-hr" }, [
                        _c("p", [_vm._v("Chaser Bank")]),
                        _c("hr"),
                      ]),
                      _c(
                        "div",
                        {
                          class: _vm.isSellerView
                            ? "div-bank-info-frac-13 div-bank-info-body"
                            : "div-frac-13 div-bank-info-body",
                        },
                        [
                          _vm.isSellerView
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    round: "",
                                    outline: "",
                                    size: "small",
                                    disabled:
                                      _vm.propsView ===
                                      _vm.INVOICE_VIEW.VIEW_INVOICE,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogChaserBank = true
                                    },
                                  },
                                },
                                [_vm._v(" Select Bank ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Name of Bank",
                                prop: "sellerChaserBankName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                model: {
                                  value: _vm.invoiceForm.sellerChaserBankName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "sellerChaserBankName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "invoiceForm.sellerChaserBankName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Swift Code",
                                prop: "sellerChaserBankSwiftCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                model: {
                                  value:
                                    _vm.invoiceForm.sellerChaserBankSwiftCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "sellerChaserBankSwiftCode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "invoiceForm.sellerChaserBankSwiftCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Country",
                                prop: "sellerChaserBankCountry",
                              },
                            },
                            [
                              _c("country-selector", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                model: {
                                  value:
                                    _vm.invoiceForm.sellerChaserBankCountry,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "sellerChaserBankCountry",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "invoiceForm.sellerChaserBankCountry",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "div-bank-info-title-hr" }, [
                        _c("p", [_vm._v("Company Bank")]),
                        _c("hr"),
                      ]),
                      _c(
                        "div",
                        {
                          class: _vm.isSellerView
                            ? "div-bank-info-frac-13 div-bank-info-body"
                            : "div-frac-13 div-bank-info-body",
                        },
                        [
                          _vm.isSellerView
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    round: "",
                                    outline: "",
                                    size: "small",
                                    disabled:
                                      _vm.propsView ===
                                      _vm.INVOICE_VIEW.VIEW_INVOICE,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogCompanyBank = true
                                    },
                                  },
                                },
                                [_vm._v(" Select Bank ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Name of Bank",
                                prop: "sellerBankName",
                                rules: _vm.isSellerView
                                  ? _vm.rules.reqRule
                                  : _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                model: {
                                  value: _vm.invoiceForm.sellerBankName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "sellerBankName",
                                      $$v
                                    )
                                  },
                                  expression: "invoiceForm.sellerBankName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Swift Code",
                                prop: "sellerBankSwiftCode",
                                rules: _vm.isSellerView
                                  ? _vm.rules.reqRule
                                  : _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                model: {
                                  value: _vm.invoiceForm.sellerBankSwiftCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "sellerBankSwiftCode",
                                      $$v
                                    )
                                  },
                                  expression: "invoiceForm.sellerBankSwiftCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Country",
                                prop: "sellerBankCountry",
                                rules: _vm.isSellerView
                                  ? _vm.rules.reqRule
                                  : _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("country-selector", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                model: {
                                  value: _vm.invoiceForm.sellerBankCountry,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "sellerBankCountry",
                                      $$v
                                    )
                                  },
                                  expression: "invoiceForm.sellerBankCountry",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: _vm.isSellerView
                            ? "div-bank-info-frac-13 div-bank-info-body"
                            : "div-frac-13 div-bank-info-body",
                        },
                        [
                          _vm.isSellerView ? _c("div") : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Account Name",
                                prop: "sellerBankAccountName",
                                rules: _vm.isSellerView
                                  ? _vm.rules.reqRule
                                  : _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                model: {
                                  value: _vm.invoiceForm.sellerBankAccountName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "sellerBankAccountName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "invoiceForm.sellerBankAccountName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Account Number",
                                prop: "sellerBankAccountNo",
                                rules: _vm.isSellerView
                                  ? _vm.rules.reqRule
                                  : _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                model: {
                                  value: _vm.invoiceForm.sellerBankAccountNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "sellerBankAccountNo",
                                      $$v
                                    )
                                  },
                                  expression: "invoiceForm.sellerBankAccountNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Account Type",
                                prop: "sellerBankAccountType",
                                rules: _vm.isSellerView
                                  ? _vm.rules.reqRule
                                  : _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                model: {
                                  value: _vm.invoiceForm.sellerBankAccountType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "sellerBankAccountType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "invoiceForm.sellerBankAccountType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: _vm.isSellerView
                            ? "div-bank-info-frac-1 div-bank-info-body"
                            : "div-frac-1 div-bank-info-body",
                        },
                        [
                          _c("div"),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Banking Terms and Conditions",
                                prop: "sellerBankBankingTermsConditions",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    _vm.propsView ===
                                    _vm.INVOICE_VIEW.VIEW_INVOICE,
                                },
                                model: {
                                  value:
                                    _vm.invoiceForm
                                      .sellerBankBankingTermsConditions,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoiceForm,
                                      "sellerBankBankingTermsConditions",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "invoiceForm.sellerBankBankingTermsConditions",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  (_vm.propsView === _vm.INVOICE_VIEW.EDIT_INVOICE ||
                    _vm.propsView === _vm.INVOICE_VIEW.VIEW_INVOICE) &&
                  _vm.hasPermission(_vm.$permissionFunctionCode.EDIT) &&
                  ((_vm.isSellerView &&
                    [
                      _vm.INVOICE_STATUS.SENT,
                      _vm.INVOICE_STATUS.PAID,
                      _vm.INVOICE_STATUS.OUTSTANDING,
                    ].includes(_vm.invoiceForm.sellerInvoiceStatus)) ||
                    (!_vm.isSellerView &&
                      _vm.invoiceForm.createdBySysOrganization ===
                        _vm.myCompanyId &&
                      [
                        _vm.INVOICE_STATUS.AUTHORIZED_SIGNATORY_APPROVED,
                        _vm.INVOICE_STATUS.PAID,
                        _vm.INVOICE_STATUS.OUTSTANDING,
                      ].includes(_vm.invoiceForm.sellerInvoiceStatus)))
                    ? _c(
                        "div",
                        { ref: "cdNoteDiv", staticClass: "basic-card-block" },
                        [
                          _c("h1", [_vm._v("Credit/Debit Note")]),
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "div-cd-note-info-body" },
                              [
                                _c("h4", [_vm._v("Credit Note")]),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      outline: "",
                                      round: "",
                                      size: "small",
                                      disabled: !_vm.isApplyCdNote,
                                    },
                                    on: {
                                      click: function ($event) {
                                        ;(_vm.isCreditNote = true),
                                          (_vm.dialogSelectCdNote = true)
                                      },
                                    },
                                  },
                                  [_vm._v(" Add ")]
                                ),
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.invoiceForm.creditNotes,
                                      border: "",
                                      "header-cell-style": { color: "#909399" },
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { label: "Invoice Number" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    scope.row.createdByInvoice
                                                      ? _c("el-input", {
                                                          attrs: {
                                                            disabled: true,
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .createdByInvoice
                                                                .userEnteredInvoiceNo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row
                                                                  .createdByInvoice,
                                                                "userEnteredInvoiceNo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.createdByInvoice.userEnteredInvoiceNo",
                                                          },
                                                        })
                                                      : _c("el-input", {
                                                          attrs: {
                                                            disabled: true,
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2427917506
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "Date Created" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-date-picker", {
                                                      attrs: {
                                                        disabled: true,
                                                        type: "date",
                                                        format: "dd-MM-yyyy",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.noteDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "noteDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.noteDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3942672999
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "Note Total" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-input-number", {
                                                      attrs: {
                                                        disabled: true,
                                                        controls: false,
                                                        precision: 3,
                                                        min: null,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.noteTotal,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "noteTotal",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.noteTotal",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1005163282
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "", width: "50px" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-button", {
                                                  attrs: {
                                                    type: "danger",
                                                    icon: "el-icon-delete",
                                                    size: "mini",
                                                    circle: "",
                                                    disabled:
                                                      !_vm.isApplyCdNote,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeCdNoteItem(
                                                        scope.$index,
                                                        true
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3537028855
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c("h4", { staticClass: "h4-debit-note" }, [
                                  _vm._v("Debit Note"),
                                ]),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      outline: "",
                                      round: "",
                                      size: "small",
                                      disabled: !_vm.isApplyCdNote,
                                    },
                                    on: {
                                      click: function ($event) {
                                        ;(_vm.isCreditNote = false),
                                          (_vm.dialogSelectCdNote = true)
                                      },
                                    },
                                  },
                                  [_vm._v(" Add ")]
                                ),
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.invoiceForm.debitNotes,
                                      border: "",
                                      "header-cell-style": { color: "#909399" },
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { label: "Invoice Number" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: true },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .createdByInvoice
                                                            .userEnteredInvoiceNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row
                                                              .createdByInvoice,
                                                            "userEnteredInvoiceNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.createdByInvoice.userEnteredInvoiceNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3853091753
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "Date Created" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-date-picker", {
                                                      attrs: {
                                                        disabled: true,
                                                        type: "date",
                                                        format: "dd-MM-yyyy",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.noteDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "noteDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.noteDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3942672999
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "Amount" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    _c("el-input-number", {
                                                      attrs: {
                                                        disabled: true,
                                                        controls: false,
                                                        precision: 3,
                                                        min: null,
                                                      },
                                                      model: {
                                                        value: scope.row.amount,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "amount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.amount",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3973474892
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "", width: "50px" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-button", {
                                                  attrs: {
                                                    type: "danger",
                                                    icon: "el-icon-delete",
                                                    size: "mini",
                                                    circle: "",
                                                    disabled:
                                                      !_vm.isApplyCdNote,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeCdNoteItem(
                                                        scope.$index,
                                                        false
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        534643900
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "div-form-main-right-summary" }, [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "div-invoice-payment-summary-main" },
                    [
                      _vm.propsView === _vm.INVOICE_VIEW.EDIT_INVOICE ||
                      _vm.propsView === _vm.INVOICE_VIEW.VIEW_INVOICE
                        ? _c("h4", [_vm._v("Invoice Number")])
                        : _vm._e(),
                      _vm.propsView === _vm.INVOICE_VIEW.EDIT_INVOICE ||
                      _vm.propsView === _vm.INVOICE_VIEW.VIEW_INVOICE
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "div-invoice-payment-summary-invoice-no",
                            },
                            [
                              _c("h1", [
                                _vm._v(
                                  _vm._s(
                                    `${_vm.invoiceForm.userEnteredInvoiceNo} ${
                                      _vm.invoiceForm.invoiceVersionNo
                                        ? `(${_vm.invoiceForm.invoiceVersionNo})`
                                        : ""
                                    }`
                                  )
                                ),
                              ]),
                              _c("i", {
                                staticClass: "el-icon-document",
                                on: {
                                  click: function ($event) {
                                    return _vm.previewFile(
                                      _vm.invoiceForm.invoiceFileId
                                    )
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "div-invoice-payment-summary-top" },
                        [
                          _vm.propsView === _vm.INVOICE_VIEW.EDIT_INVOICE ||
                          _vm.propsView === _vm.INVOICE_VIEW.VIEW_INVOICE
                            ? _c("div", [
                                _c("p", [_vm._v("Invoice Status")]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "status-tag",
                                    style: _vm.getStatusTagStyle(
                                      _vm.isSellerView
                                        ? _vm.invoiceForm.sellerInvoiceStatus
                                        : _vm.invoiceForm.buyerInvoiceStatus
                                    ),
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isSellerView
                                            ? _vm.capitalizeFirstLetter(
                                                _vm.invoiceForm
                                                  .sellerInvoiceStatus,
                                                "_"
                                              )
                                            : _vm.capitalizeFirstLetter(
                                                _vm.invoiceForm
                                                  .buyerInvoiceStatus,
                                                "_"
                                              )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c("h4", [_vm._v("Invoice Summary")]),
                      _c(
                        "div",
                        { staticClass: "div-invoice-payment-currency" },
                        [
                          _c("p", [
                            _vm._v(
                              "Payment Currency: " +
                                _vm._s(_vm.invoiceForm.orderCurrency || "N.A.")
                            ),
                          ]),
                        ]
                      ),
                      _vm.propsView === _vm.INVOICE_VIEW.CREATE_INVOICE
                        ? _c(
                            "div",
                            { staticClass: "div-invoice-payment-summary" },
                            [
                              _c("div", [
                                _c("p", [_vm._v("Subtotal")]),
                                _vm._m(0),
                                _vm.invoicePaymentInfo.subTotal
                                  ? _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            `${
                                              _vm.invoiceForm.orderCurrency
                                            } ${_vm.numberWithCommas(
                                              _vm.invoicePaymentInfo.subTotal,
                                              2
                                            )}`
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.invoiceForm.orderCurrency
                                          ) + " 0.00"
                                        ),
                                      ]
                                    ),
                              ]),
                              _c("div", [
                                _c("p", [_vm._v("Tax")]),
                                _vm._m(1),
                                _vm.invoicePaymentInfo.taxTotal
                                  ? _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            `${
                                              _vm.invoiceForm.orderCurrency
                                            } ${_vm.numberWithCommas(
                                              _vm.invoicePaymentInfo.taxTotal,
                                              2
                                            )}`
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.invoiceForm.orderCurrency
                                          ) + " 0.00"
                                        ),
                                      ]
                                    ),
                              ]),
                              _c("div", [
                                _c(
                                  "p",
                                  { staticStyle: { "font-weight": "500" } },
                                  [_vm._v("Invoice Total")]
                                ),
                                _vm._m(2),
                                _vm.invoicePaymentInfo &&
                                _vm.invoicePaymentInfo.invoiceTotal
                                  ? _c(
                                      "p",
                                      { staticStyle: { "font-weight": "500" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            `${
                                              _vm.invoiceForm.orderCurrency
                                            } ${_vm.numberWithCommas(
                                              _vm.invoicePaymentInfo
                                                .invoiceTotal,
                                              2
                                            )}`
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "p",
                                      { staticStyle: { "font-weight": "500" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.invoiceForm.orderCurrency
                                          ) + " 0.00"
                                        ),
                                      ]
                                    ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.propsView === _vm.INVOICE_VIEW.EDIT_INVOICE ||
                      _vm.propsView === _vm.INVOICE_VIEW.VIEW_INVOICE
                        ? _c(
                            "div",
                            { staticClass: "div-invoice-payment-summary" },
                            [
                              _c("div", [
                                _c("p", [_vm._v("Invoice Total")]),
                                _vm._m(3),
                                _vm.invoicePaymentReceiptSettlement &&
                                _vm.invoicePaymentReceiptSettlement.invoiceTotal
                                  ? _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              `${
                                                _vm.invoiceForm.orderCurrency
                                              } ${_vm.numberWithCommas(
                                                _vm
                                                  .invoicePaymentReceiptSettlement
                                                  .invoiceTotal,
                                                2
                                              )}`
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.invoiceForm.orderCurrency
                                          ) + " 0.00"
                                        ),
                                      ]
                                    ),
                              ]),
                              _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "Total Amount " +
                                      _vm._s(
                                        _vm.isSellerView
                                          ? "Receivable"
                                          : "Payable"
                                      )
                                  ),
                                ]),
                                _vm._m(4),
                                _vm.invoicePaymentReceiptSettlement &&
                                _vm.invoicePaymentReceiptSettlement
                                  .totalAmountReceivable &&
                                _vm.isSellerView
                                  ? _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              `${
                                                _vm.invoiceForm.orderCurrency
                                              } ${_vm.numberWithCommas(
                                                _vm
                                                  .invoicePaymentReceiptSettlement
                                                  .totalAmountReceivable,
                                                2
                                              )}`
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm.invoicePaymentReceiptSettlement &&
                                    _vm.invoicePaymentReceiptSettlement
                                      .totalAmountPayable &&
                                    !_vm.isSellerView
                                  ? _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              `${
                                                _vm.invoiceForm.orderCurrency
                                              } ${_vm.numberWithCommas(
                                                _vm
                                                  .invoicePaymentReceiptSettlement
                                                  .totalAmountPayable,
                                                2
                                              )}`
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.invoiceForm.orderCurrency
                                          ) + " 0.00"
                                        ),
                                      ]
                                    ),
                              ]),
                              _c("div", [
                                _c("p", [_vm._v("Outstanding Amount")]),
                                _vm._m(5),
                                _vm.invoicePaymentReceiptSettlement &&
                                _vm.invoicePaymentReceiptSettlement
                                  .receivableOutstandingAmount &&
                                _vm.isSellerView
                                  ? _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              `${
                                                _vm.invoiceForm.orderCurrency
                                              } ${_vm.numberWithCommas(
                                                _vm
                                                  .invoicePaymentReceiptSettlement
                                                  .receivableOutstandingAmount,
                                                2
                                              )}`
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm.invoicePaymentReceiptSettlement &&
                                    _vm.invoicePaymentReceiptSettlement
                                      .receivableOutstandingAmount &&
                                    !_vm.isSellerView
                                  ? _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              `${
                                                _vm.invoiceForm.orderCurrency
                                              } ${_vm.numberWithCommas(
                                                _vm
                                                  .invoicePaymentReceiptSettlement
                                                  .payableOutstandingAmount,
                                                2
                                              )}`
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "p",
                                      { staticClass: "p-invoice-summary-amt" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.invoiceForm.orderCurrency
                                          ) + " 0.00"
                                        ),
                                      ]
                                    ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.propsView === _vm.INVOICE_VIEW.CREATE_INVOICE
                        ? _c(
                            "div",
                            { staticClass: "div-invoice-payment-summary-btm" },
                            [
                              _c("hr", {
                                staticStyle: {
                                  "border-top": "1px solid #F4F4F4",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "div-invoice-payment-currency" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "Exchange Currency: " +
                                        _vm._s(
                                          _vm.invoiceForm.exchangeCurrency ||
                                            "N.A."
                                        ) +
                                        " (for GST purpose)"
                                    ),
                                  ]),
                                ]
                              ),
                              _vm.propsView === _vm.INVOICE_VIEW.CREATE_INVOICE
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "div-invoice-payment-summary",
                                    },
                                    [
                                      _c("div", [
                                        _c("p", [
                                          _vm._v("Total Amount before GST"),
                                        ]),
                                        _vm._m(6),
                                        _vm.invoicePaymentInfo
                                          .totalAmountBeforeGst
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "p-invoice-summary-amt",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    `${
                                                      _vm.invoiceForm
                                                        .exchangeCurrency
                                                    } ${_vm.numberWithCommas(
                                                      _vm.invoicePaymentInfo
                                                        .totalAmountBeforeGst,
                                                      2
                                                    )}`
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "p-invoice-summary-amt",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.invoiceForm
                                                      .exchangeCurrency
                                                  ) + " 0.00"
                                                ),
                                              ]
                                            ),
                                      ]),
                                      _c("div", [
                                        _c("p", [_vm._v("GST Amount")]),
                                        _vm._m(7),
                                        _vm.invoicePaymentInfo.gstAmount
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "p-invoice-summary-amt",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    `${
                                                      _vm.invoiceForm
                                                        .exchangeCurrency
                                                    } ${_vm.numberWithCommas(
                                                      _vm.invoicePaymentInfo
                                                        .gstAmount,
                                                      2
                                                    )}`
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "p-invoice-summary-amt",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.invoiceForm
                                                      .exchangeCurrency
                                                  ) + " 0.00"
                                                ),
                                              ]
                                            ),
                                      ]),
                                      _c("div", [
                                        _c("p", [
                                          _vm._v("Total Amount after GST"),
                                        ]),
                                        _vm._m(8),
                                        _vm.invoicePaymentInfo
                                          .totalAmountAfterGst
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "p-invoice-summary-amt",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    `${
                                                      _vm.invoiceForm
                                                        .exchangeCurrency
                                                    } ${_vm.numberWithCommas(
                                                      _vm.invoicePaymentInfo
                                                        .totalAmountAfterGst,
                                                      2
                                                    )}`
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "p-invoice-summary-amt",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.invoiceForm
                                                      .exchangeCurrency
                                                  ) + " 0.00"
                                                ),
                                              ]
                                            ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("dialog-select-order", {
        attrs: {
          isSellerView: _vm.isSellerView,
          isCommissionInvoice: _vm.isCommissionInvoice,
          dialogSelectOrder: _vm.dialogSelectOrder,
          orderType: _vm.invoiceForm.orderType,
          orderSelectedId: _vm.invoiceForm.orderId,
        },
        on: {
          handleOrderDialogClose: function ($event) {
            _vm.dialogSelectOrder = false
          },
          handleConfirmOrderClick: _vm.handleConfirmOrderClick,
        },
      }),
      _c("dialog-select-invoice-items", {
        attrs: {
          isCommissionInvoice: _vm.isCommissionInvoice,
          dialogSelectInvoiceItems: _vm.dialogSelectInvoiceItems,
          order: _vm.orderSelected,
          propsView: _vm.propsView,
          selectedItems: _vm.invoiceForm.invoiceItems || [],
          invoiceType: _vm.invoiceForm.invoiceType,
          isSellerView: _vm.isSellerView,
        },
        on: {
          handleConfirmInvoiceItemsClick: _vm.handleConfirmInvoiceItemsClick,
          handleInvoiceItemsDialogClose: function ($event) {
            _vm.dialogSelectInvoiceItems = false
          },
        },
      }),
      _c("dialog-select-cd-note", {
        attrs: {
          buyerSysOrganizationId: _vm.invoiceForm.buyerSysOrganizationId,
          isCreditNote: _vm.isCreditNote,
          dialogSelectCdNote: _vm.dialogSelectCdNote,
          propsCreditNoteSelected: _vm.invoiceForm.creditNotes,
          propsDebitNoteSelected: _vm.invoiceForm.debitNotes,
          appliedNotes: _vm.invoiceForm.appliedNotes,
        },
        on: {
          handleSelectCdNoteDialogClose: function ($event) {
            _vm.dialogSelectCdNote = false
          },
          handleConfirmCdNoteDialogClick: _vm.handleConfirmCdNoteDialogClick,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-update-bank",
          attrs: {
            visible: _vm.dialogCompanyBank || _vm.dialogChaserBank,
            width: "90%",
          },
          on: {
            close: function ($event) {
              _vm.dialogCompanyBank
                ? (_vm.dialogCompanyBank = false)
                : (_vm.dialogChaserBank = false)
            },
          },
        },
        [
          _vm.dialogCompanyBank || _vm.dialogChaserBank
            ? _c("update-bank", {
                attrs: {
                  isViewOnly: "",
                  hasButtons: "",
                  hasRadio: "",
                  refKey: _vm.dialogCompanyBank ? "companyBank" : "chaserBank",
                  category: _vm.dialogCompanyBank
                    ? "COMPANY_BANK"
                    : "CHASER_BANK",
                  sysOrganizationId: _vm.myCompanyId,
                  data: _vm.bankList.filter(
                    (item) =>
                      item.bankType ===
                      (_vm.dialogCompanyBank ? "COMPANY_BANK" : "CHASER_BANK")
                  ),
                },
                on: {
                  handleConfirmBankClick: _vm.handleConfirmBankClick,
                  handleCloseBankDialog: function ($event) {
                    _vm.dialogCompanyBank
                      ? (_vm.dialogCompanyBank = false)
                      : (_vm.dialogChaserBank = false)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("hr", { staticStyle: { "border-top": "2px dotted grey" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }